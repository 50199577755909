import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageContainer, ProDescriptions } from "@ant-design/pro-components";
import dayjs from "dayjs";
import { Button, Card, Divider, Image } from "antd";
import { getUserData } from "../../../api";

const UserDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [userData, setUserData] = useState([]);
  // const [columnsStateMap, setColumnsStateMap] = useState({
  //   matchNameEn: {
  //     show: false,
  //     order: 0,
  //   },
  // });

  const fetchUserData = useCallback(async () => {
    try {
      const response = await getUserData(id);

      setUserData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return (
    <>
      {userData && (
        <PageContainer
          title={`User details`}
          extra={[
            <Button
              key="edit-waiting-room"
              type="primary"
              onClick={() => navigate(`edit`)}
            >
              Edit User
            </Button>,
          ]}
        >
          <Card title="User Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item label="User ID" valueType="text">
                {userData?.id}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Full name" valueType="text">
                {userData?.fullName}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Email" valueType="text">
                {userData?.email}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Phone number" valueType="text">
                {userData?.phoneNumber}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="City" valueType="text">
                {userData?.city}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Country" valueType="text">
                {userData?.nationality}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Date of birth" valueType="text">
                {dayjs(userData?.dateOfBirth)
                  .tz("Asia/Kuwait")
                  .format("YYYY/MM/DD")
                  .toString()}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Avatar">
                {userData?.avatar?.length < 1 ? (
                  <p>No image</p>
                ) : (
                  <Image width={150} src={userData?.avatar} />
                )}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <Card title="User Role">
            <ProDescriptions column={1}>
              <ProDescriptions.Item label="Role" valueType="text">
                {userData?.roles}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Is verify?" valueType="text">
                {userData?.isVerify ? "TRUE" : "FALSE"}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Created at">
                {dayjs(userData?.createdAt).format("YYYY-MM-DD HH:mm")}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>
        </PageContainer>
      )}
    </>
  );
};

export default UserDetails;
