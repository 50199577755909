import { PlusOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal, notification } from "antd";
import { ProTable, PageContainer } from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { deleteCoupon, getCoupons } from "../../api";
import { createQueryStringUrl } from "../../utils/createQueryStringUrl";
import { useCouponTableColumns } from "../../components/Columns/Coupon";

const Coupon = () => {
  const navigate = useNavigate();
  const [form] = useForm();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(null);
  const [coupons, setCoupons] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState();
  const [isShowModal, setIsShowModal] = useState(false);
  const [filterEvents, setFilterEvents] = useState();
  const [columnsStateMap, setColumnsStateMap] = useState({
    nameEn: {
      show: false,
      order: 0,
    },
    locationEn: {
      show: false,
      order: 0,
    },
    cityEn: {
      show: false,
      order: 0,
    },
  });

  const showModal = (record) => {
    setSelectedRecord(record);
    setIsShowModal(true);
  };

  const handleCancel = () => setIsShowModal(false);

  const fetchCoupons = useCallback(async () => {
    try {
      createQueryStringUrl(filterEvents);
      form.setFieldsValue(filterEvents);

      const params = {
        ...filterEvents,
        page: currentPage,
        limit: pageSize,
      };

      const response = await getCoupons(params);

      setCoupons(response?.results);
      setTotalItems(response?.total);
    } catch (error) {
      console.log("Error", error);
    }
  }, [currentPage, filterEvents, form, pageSize]);

  const handleSubmitModal = async () => {
    try {
      const response = await deleteCoupon(selectedRecord?.id);
      if (response.status === 200) {
        await fetchCoupons();
        notification.success({
          message: "Delete coupon successful! My friends",
        });
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      handleCancel();
    }
  };

  const handleSubmitFilter = (params) => {
    const formValues = form.getFieldsValue();

    setFilterEvents({
      ...filterEvents,
      couponInfo: params?.couponInfo || formValues?.couponInfo || null,
      eventInfo: params?.eventInfo || formValues?.eventInfo || null,
      ticketTypeInfo:
        params?.ticketTypeInfo || formValues?.ticketTypeInfo || null,
    });

    setCurrentPage(1);
  };

  const handleResetFilter = () => {
    setFilterEvents({
      eventInfo: null,
      ticketTypeInfo: null,
      couponInfo: null,
    });
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchCoupons();
  }, [fetchCoupons]);

  const tableColumns = useCouponTableColumns(showModal);

  return (
    <PageContainer title="All Coupon">
      {isShowModal && (
        <Modal
          open={isShowModal}
          onOk={() => handleSubmitModal()}
          onCancel={handleCancel}
          centered
        >
          <div className="delete-content">
            <ExclamationCircleFilled style={{ fontSize: "48px" }} />

            <p>Are you sure to delete this record?</p>
          </div>
        </Modal>
      )}

      <ProTable
        rowKey="id"
        request={({ pageSize, current }, sort, filter) => {
          setPageSize(pageSize);

          return {
            // success,
            // total,
            filter,
          };
        }}
        onSubmit={(params) => handleSubmitFilter(params)}
        onReset={handleResetFilter}
        // onRow={(record) => {
        //   return {
        //     onClick: () => {
        //       navigate(`/events/${record?.id}`);
        //     },
        //   };
        // }}
        scroll={{ x: 2600, y: 500 }}
        columns={tableColumns}
        dataSource={coupons}
        pagination={{
          pageSize: pageSize,
          total: totalItems,
          onChange: (page) => setCurrentPage(page),
        }}
        toolBarRender={() => [
          <Button
            key="button"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate("new");
            }}
            type="primary"
          >
            New Coupon
          </Button>,
        ]}
        search={{
          form: form,
          labelWidth: "auto",
        }}
        columnsState={{
          value: columnsStateMap,
          onChange: setColumnsStateMap,
        }}
      ></ProTable>
    </PageContainer>
  );
};

export default Coupon;
