import React from "react";
import { Modal } from "antd";
import { ADD } from "../../../utils/constant";
import { ExclamationCircleFilled } from "@ant-design/icons";

const AddSponsorsModal = ({ isOpen, handleOk = () => {}, handleCancel }) => {
  return (
    <Modal
      open={isOpen}
      onOk={() => handleOk(ADD)}
      onCancel={handleCancel}
      centered
    >
      <div className="delete-content">
        <ExclamationCircleFilled style={{ fontSize: "48px" }} />
        <p>Are you sure to add sponsors?</p>
      </div>
    </Modal>
  );
};

export default AddSponsorsModal;
