import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageContainer, ProDescriptions } from "@ant-design/pro-components";
import dayjs from "dayjs";
import { Button, Card, Divider, Image } from "antd";
import { getClubData } from "../../../api";

const ClubDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [clubData, setClubData] = useState();

  const fetchClubData = useCallback(async () => {
    try {
      const response = await getClubData(id);

      setClubData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  useEffect(() => {
    fetchClubData();
  }, [fetchClubData]);

  return (
    <>
      {clubData && (
        <PageContainer
          title={`Club details`}
          extra={[
            <Button
              key="edit-waiting-room"
              type="primary"
              onClick={() => navigate(`edit`)}
            >
              Edit club
            </Button>,
          ]}
        >
          <Card title="Club Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item
                label="Club ID"
                valueType="text"
                span={1}
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {clubData?.id}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="English name" valueType="text">
                {clubData?.nameEn}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Arabic name" valueType="text">
                {clubData?.nameAr}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <Card title="Club Logo">
            <ProDescriptions column={1}>
              <ProDescriptions.Item label="Created at">
                {dayjs(clubData?.createdAt).format("YYYY-MM-DD HH:mm")}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Logo">
                <Image width={150} src={clubData?.logo} />
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>
        </PageContainer>
      )}
    </>
  );
};

export default ClubDetails;
