import { PageContainer, ProTable } from "@ant-design/pro-components";
import { Spin, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  SortingDate,
  SortingNumber,
  SortingText,
} from "../../utils/sortingData";
import dayjs from "dayjs";
import { useForm } from "antd/lib/form/Form";
import { useLocation } from "react-router-dom";
import { parseQueryString } from "../../utils/parseQueryString";
import useEventEnter from "../../utils/useEventEnter";
import { createQueryStringUrl } from "../../utils/createQueryStringUrl";
import { getPaymentLogs } from "../../api";
import PaymentLogDraw from "./PaymentLogDraw";

const { Text } = Typography;

const ENUM_STATUS = {
  SUCCESS: {
    text: "SUCCESS",
  },
  FAILURE: {
    text: "FAILURE",
  },
  PENDING: {
    text: "PENDING",
  },
};

const ENUM_PAYMENT_OPTION = {
  VISA: {
    text: "VISA",
  },
  MASTERCARD: {
    text: "MASTER CARD",
  },
  MADA: {
    text: "MADA",
  },
};

const PaymentLogs = () => {
  const [form] = useForm();
  const { search } = useLocation();
  const queryString = parseQueryString(search);
  const customQueryString = {
    customer_email: queryString.customer_email || null,
    phone_number: queryString.phone_number || null,
    payment_option: queryString.payment_option || null,
    transaction_status: queryString.transaction_status || null,
    from_date: queryString?.from_date || null,
    to_date: queryString?.to_date || null,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [paymentLogs, setPaymentLogs] = useState([]);
  const [filterPaymentLogs, setFilterPaymentLogs] = useState(customQueryString);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalItems: 0,
  });
  const [isShowPaymentLogDraw, setIsShowPaymentLogDraw] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const togglePaymentLogDraw = () =>
    setIsShowPaymentLogDraw((prevState) => !prevState);

  const fetchPaymentLogs = useCallback(async () => {
    setIsLoading(true);
    try {
      createQueryStringUrl(filterPaymentLogs);
      const formatFilterValues = {
        ...filterPaymentLogs,
        created_at: [filterPaymentLogs.from_date, filterPaymentLogs.to_date],
      };

      form.setFieldsValue(formatFilterValues);

      const response = await getPaymentLogs(
        filterPaymentLogs,
        pagination.currentPage,
        pagination.pageSize
      );

      setPaymentLogs(response?.data);
      setPagination((prevState) => ({
        ...prevState,
        totalItems: response.meta.totalItems,
        currentPage: response.meta.currentPage,
        pageSize: response.meta.itemsPerPage,
      }));
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }, [filterPaymentLogs, form, pagination.currentPage, pagination.pageSize]);

  const handleSubmitFilter = async (params) => {
    const formValues = form.getFieldsValue();

    const startDate = dayjs.utc(
      new Date(params?.created_at?.[0] || formValues?.created_at?.[0])
    );
    const endDate = dayjs.utc(
      new Date(params?.created_at?.[1] || formValues?.created_at?.[1])
    );

    setFilterPaymentLogs({
      ...filterPaymentLogs,
      customer_email:
        params?.customer_email || formValues?.customer_email || null,
      phone_number: params?.phone_number || formValues?.phone_number || null,
      payment_option:
        params?.payment_option || formValues?.payment_option || null,
      transaction_status:
        params?.transaction_status || formValues?.transaction_status || null,
      from_date:
        params?.created_at?.[0] && formValues?.created_at?.[0]
          ? startDate.startOf("day").utc().format()
          : null,
      to_date:
        params?.created_at?.[1] && formValues?.created_at?.[1]
          ? endDate.endOf("day").utc().format()
          : null,
    });

    setPagination((prevState) => ({ ...prevState, currentPage: 1 }));
  };

  const handleResetFilter = () => {
    setFilterPaymentLogs({
      customer_email: null,
      phone_number: null,
      payment_option: null,
      transaction_status: null,
      from_date: null,
      to_date: null,
    });
    setPagination((prevState) => ({ ...prevState, currentPage: 1 }));
  };

  const handleClickOnRow = (record, rowIndex) => {
    togglePaymentLogDraw();
    setSelectedRow(record);
  };

  useEventEnter(handleSubmitFilter);

  useEffect(() => {
    fetchPaymentLogs();
  }, [fetchPaymentLogs]);

  const tableColumns = [
    {
      key: "customer_email",
      title: "Customer email",
      dataIndex: ["payfort_response", "customer_email"],
      sorter: (current, next) =>
        SortingText(
          current.payfort_response.customer_email,
          next.payfort_response.customer_email
        ),
    },
    {
      key: "phone_number",
      title: "Phone number",
      dataIndex: ["payfort_response", "phone_number"],
      sorter: (current, next) =>
        SortingNumber(
          current.payfort_response.phone_number,
          next.payfort_response.phone_number
        ),
    },
    {
      key: "fort_id",
      title: "Fort ID",
      dataIndex: "fort_id",
      search: false,
      // sorter: (current, next) => SortingText(current.fort_id, next.fort_id),
    },
    {
      key: "response_code",
      title: "Response code",
      dataIndex: "response_code",
      search: false,
      sorter: (current, next) =>
        SortingNumber(current.response_code, next.response_code),
    },
    {
      key: "response_message",
      title: "Response message",
      dataIndex: "response_message",
      search: false,
      sorter: (current, next) =>
        SortingText(current.response_message, next.response_message),
    },
    {
      key: "amount",
      title: "Amount",
      // dataIndex: ["payfort_response", "amount"],
      search: false,
      sorter: (current, next) =>
        SortingNumber(
          current.payfort_response.amount,
          next.payfort_response.amount
        ),
      render: (_, record) => {
        return (
          <Text>
            {record.payfort_response.amount
              ? `SAR ${Number(record.payfort_response.amount) / 100}`
              : "-"}
          </Text>
        );
      },
    },
    {
      key: "service_command",
      title: "Service command",
      dataIndex: "service_command",
      search: false,
      // sorter: (current, next) =>
      //   SortingText(current.service_command, next.service_command),
    },
    {
      key: "payment_option",
      title: "Payment option",
      dataIndex: ["payfort_response", "payment_option"],
      valueEnum: ENUM_PAYMENT_OPTION,
      // sorter: (current, next) =>
      //   SortingText(
      //     current.payfort_response.payment_option,
      //     next.payfort_response.payment_option
      //   ),
    },
    {
      key: "transaction_status",
      title: "Transaction status",
      dataIndex: "transaction_status",
      valueEnum: ENUM_STATUS,
      // sorter: (current, next) =>
      //   SortingText(current.transaction_status, next.transaction_status),
    },
    {
      key: "created_at",
      title: "Created At",
      dataIndex: "created_at",
      valueType: "dateTimeRange",
      fieldProps: {
        placeholder: ["Start date", "End date"],
        // showTime: { format: "HH:mm" },
        showTime: false,
      },
      sorter: (current, next) =>
        SortingDate(current.created_at, next.created_at),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record?.created_at)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
  ];

  return (
    <PageContainer title="All payment logs">
      <ProTable
        rowKey="id"
        loading={{
          indicator: <Spin className="spin-icon" />,
          spinning: isLoading,
        }}
        request={async ({ pageSize, current }, sort, filter) => {
          setPagination((prevState) => ({ ...prevState, pageSize }));

          return {
            paymentLogs,
            // success,
            // total,
            filter,
          };
        }}
        onSubmit={(params) => handleSubmitFilter(params)}
        onReset={handleResetFilter}
        scroll={{ x: 1800 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => handleClickOnRow(record, rowIndex),
          };
        }}
        columns={tableColumns}
        dataSource={paymentLogs}
        search={{
          labelWidth: "auto",
          form: form,
        }}
        pagination={{
          pageSize: pagination.pageSize,
          total: pagination.totalItems,
          onChange: (page) =>
            setPagination((prevState) => ({ ...prevState, currentPage: page })),
        }}
      ></ProTable>

      {isShowPaymentLogDraw && (
        <PaymentLogDraw
          open={isShowPaymentLogDraw}
          cancel={togglePaymentLogDraw}
          paymentLogData={selectedRow}
        />
      )}
    </PageContainer>
  );
};

export default PaymentLogs;
