import React from "react";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { REMOVE } from "../../../utils/constant";

const RemovePermissionModal = ({
  isOpen,
  handleOk = () => {},
  handleCancel,
}) => {
  return (
    <Modal
      open={isOpen}
      onOk={() => handleOk(REMOVE)}
      onCancel={handleCancel}
      centered
    >
      <div className="delete-content">
        <ExclamationCircleFilled style={{ fontSize: "48px" }} />
        <p>Are you sure to remove permission?</p>
      </div>
    </Modal>
  );
};

export default RemovePermissionModal;
