import api from "../utils/axios-client";

// sponsors
export const getSponsors = async (params) => {
  try {
    const response = await api.get(`operator/sponsors`, {
      params: {
        ...params,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const getSponsorsEvent = async (id, params) => {
  try {
    const response = await api.get(`operator/events/${id}/sponsors/select`, {
      params: {
        ...params,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const getSponsorData = async (recordId) => {
  try {
    const response = await api.get(`operator/sponsors/${recordId}`);

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const postSponsor = async (params) => {
  try {
    const response = await api.post(`operator/sponsors`, params);

    return response;
  } catch (error) {
    console.log("Error", error);
  }
};

export const patchSponsor = async (recordId, params) => {
  try {
    const response = await api.patch(`operator/sponsors/${recordId}`, params);

    return response;
  } catch (error) {
    console.log("Error", error);
  }
};

export const deleteSponsor = async (recordId) => {
  try {
    const response = await api.delete(`operator/sponsors/${recordId}`);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};
