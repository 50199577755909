import React from "react";
import { Button, Form, Modal } from "antd";
import { ProForm, ProFormSelect } from "@ant-design/pro-components";
import { ADD, EVENT_ROLES } from "../../../utils/constant";
import { useForm } from "antd/lib/form/Form";

const AddPermissionModal = ({ isOpen, handleOk = () => {}, handleCancel }) => {
  const [form] = useForm();

  const SubmitButton = ({ form }) => {
    const [submittable, setSubmittable] = React.useState(false);

    // Watch all values
    const values = Form.useWatch([], form);
    React.useEffect(() => {
      form
        .validateFields({
          validateOnly: true,
        })
        .then(
          () => {
            setSubmittable(true);
          },
          () => {
            setSubmittable(false);
          }
        );
    }, [form, values]);

    return (
      <Button type="primary" htmlType="submit" disabled={!submittable}>
        Submit
      </Button>
    );
  };

  const handleSubmit = (values) => {
    handleOk(ADD, values?.role);
    handleCancel();
  };

  return (
    <Modal
      title="Add permission"
      open={isOpen}
      // onOk={handleSubmit}
      onCancel={handleCancel}
      footer={null}
      centered
    >
      <ProForm
        form={form}
        name="createEventForm"
        layout="horizontal"
        onFinish={handleSubmit}
        className="create-event-form"
        onFinishFailed={({ values }) => {
          console.log("🚀 ~ values:", values);
        }}
        submitter={{
          // Configure the properties of the button
          resetButtonProps: {
            style: {
              // Hide the reset button
              // display: "none",
            },
          },
          submitButtonProps: {},

          // Fully customize the entire area
          render: (props, doms) => {
            console.log(props);
            return [
              <SubmitButton form={form} />,
              <Button onClick={handleCancel}>Cancel</Button>,
            ];
          },
        }}
      >
        <ProForm.Group>
          <div className="form-fields-wrapper">
            <ProFormSelect
              name="role"
              width={"md"}
              label="Permission"
              placeholder="Select role"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Permission is required! My friends
                    </p>
                  ),
                },
              ]}
              options={EVENT_ROLES}
            />
          </div>
        </ProForm.Group>
      </ProForm>
    </Modal>
  );
};

export default AddPermissionModal;
