export const buildQueryString = function (object) {
  let args = [];

  if (Object.prototype.toString.call(object) !== "[object Object]") return "";

  for (const key in object) {
    destructure(key, object[key]);
  }

  return args.join("&");

  function destructure(key, value) {
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        destructure(key + "[" + i + "]", value[i]);
      }
    } else if (Object.prototype.toString.call(value) === "[object Object]") {
      for (let i in value) {
        destructure(key + "[" + i + "]", value[i]);
      }
    } else
      args.push(
        encodeURIComponent(key) +
          (value != null && value !== "" ? "=" + encodeURIComponent(value) : "")
      );
  }
};
