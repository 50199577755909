import {
  ProForm,
  ProFormDigit,
  ProFormSelect,
} from "@ant-design/pro-components";
import React, { useState } from "react";
import CustomEditorToolbar from "../../components/RichTextEditorToolbar.js";

const SportType = ({ clubs, formData, eventData = {} }) => {
  const [contentEditor, setContentEditor] = useState({
    descriptionEn: eventData?.metaData?.descriptionEn || "",
    descriptionAr: eventData?.metaData?.descriptionAr || "",
    conditionEn: eventData?.metaData?.conditionEn || "",
    conditionAr: eventData?.metaData?.conditionAr || "",
  });

  const handleChange = (value, key) => {
    const emptyString = "<p><br></p>";

    if (value === emptyString) {
      formData.setFieldValue(["metaData", key], null);
      setContentEditor((prevState) => ({ ...prevState, [key]: null }));
    } else {
      formData.setFieldValue(["metaData", key], value);
      setContentEditor((prevState) => ({ ...prevState, [key]: value }));
    }

    formData.validateFields([["metaData", key]]);
  };
  return (
    <>
      <ProFormSelect
        name={["metaData", "teamHostId"]}
        width={"lg"}
        label="Host team"
        placeholder="Select host id"
        rules={[
          {
            required: true,
            message: () => (
              <p className="error-message">Host team is required! My friends</p>
            ),
          },
        ]}
        options={clubs}
      />

      <ProFormSelect
        name={["metaData", "teamGuestId"]}
        width={"lg"}
        label="Guest team"
        placeholder="Select guest id"
        rules={[
          {
            required: true,
            message: () => (
              <p className="error-message">
                Guest team is required! My friends
              </p>
            ),
          },
        ]}
        options={clubs}
      />

      <ProFormDigit
        name={["metaData", "hostScore"]}
        label="Host score"
        width={"lg"}
        placeholder="Enter host score"
        min={0}
        rules={[
          {
            required: true,
            message: () => (
              <p className="error-message">
                Host score is required! My friends
              </p>
            ),
          },
        ]}
      />

      <ProFormDigit
        name={["metaData", "guestScore"]}
        width={"lg"}
        label="Guest score"
        placeholder="Enter guest score"
        min={0}
        rules={[
          {
            required: true,
            message: () => (
              <p className="error-message">
                Guest score is required! My friends
              </p>
            ),
          },
        ]}
      />

      <ProForm.Item
        label="English Description"
        name={["metaData", "descriptionEn"]}
        className="w-full editor-field"
        rules={[
          {
            required: true,
            message: () => (
              <p className="error-message">
                English description is required! My friends
              </p>
            ),
          },
        ]}
      >
        <CustomEditorToolbar
          handleChange={(value) => handleChange(value, "descriptionEn")}
          contentEditor={contentEditor.descriptionEn}
        />
      </ProForm.Item>

      <ProForm.Item
        label="Arabic Description"
        name={["metaData", "descriptionAr"]}
        className="w-full editor-field"
        style={{ height: 200 }}
        rules={[
          {
            required: true,
            message: () => (
              <p className="error-message">
                Arabic description is required! My friends
              </p>
            ),
          },
        ]}
      >
        <CustomEditorToolbar
          handleChange={(value) => handleChange(value, "descriptionAr")}
          contentEditor={contentEditor.descriptionAr}
        />
      </ProForm.Item>

      <ProForm.Item
        label="English Condition"
        name={["metaData", "conditionEn"]}
        className="w-full editor-field"
        style={{ height: 200 }}
        rules={[
          {
            required: true,
            message: () => (
              <p className="error-message">
                English conditions is required! My friends
              </p>
            ),
          },
        ]}
      >
        <CustomEditorToolbar
          handleChange={(value) => handleChange(value, "conditionEn")}
          contentEditor={contentEditor.conditionEn}
        />
      </ProForm.Item>

      <ProForm.Item
        label="Arabic Condition"
        name={["metaData", "conditionAr"]}
        className="w-full editor-field"
        style={{ height: 200 }}
        rules={[
          {
            required: true,
            message: () => (
              <p className="error-message">
                Arabic conditions is required! My friends
              </p>
            ),
          },
        ]}
      >
        <CustomEditorToolbar
          handleChange={(value) => handleChange(value, "conditionAr")}
          contentEditor={contentEditor.conditionAr}
        />
      </ProForm.Item>
    </>
  );
};

export default SportType;
