import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Typography, Button, notification } from "antd";
import {
  ProTable,
  PageContainer,
  ProFormText,
} from "@ant-design/pro-components";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { useParams, useLocation } from "react-router-dom";
import {
  FileTextFilled,
  EditFilled,
  CheckCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import {
  SortingDate,
  SortingNumber,
  SortingText,
} from "../../../utils/sortingData";
import {
  deleteUserPermission,
  getEventUsers,
  postUserPermission,
} from "../../../api";
import { useForm } from "antd/lib/form/Form";
import AddPermissionModal from "../../../components/Permission/AddPermissionModal";
import RemovePermissionModal from "../../../components/Permission/RemovePermissionModal";
import {
  ADD,
  EVENT_OPERATOR,
  GATE_OPERATOR,
  REMOVE,
} from "../../../utils/constant";
import { parseQueryString } from "../../../utils/parseQueryString";
import { createQueryStringUrl } from "../../../utils/createQueryStringUrl";

const { Text } = Typography;

const Permissions = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = useForm();
  const { search } = useLocation();
  const queryString = parseQueryString(search);
  const customQueryString = {
    keyWord: queryString?.keyWord || null,
    roles: queryString?.roles || null,
    permission: queryString?.permission || null,
    // startDate: queryString?.startDate || null,
    // endDate: queryString?.endDate || null,
  };

  const [users, setUsers] = useState([]);
  const [filterUsers, setFilterUsers] = useState(customQueryString);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(null);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [isLoading, setIsLoading] = useState({
    post: false,
    delete: false,
  });
  const [isShowModal, setIsShownModal] = useState({
    addPermission: false,
    removePermission: false,
  });

  const handleToggleRemovePermissionModal = () =>
    setIsShownModal((prevState) => ({
      ...prevState,
      removePermission: !prevState.removePermission,
    }));

  const handleToggleAddPermissionModal = () =>
    setIsShownModal((prevState) => ({
      ...prevState,
      addPermission: !prevState.addPermission,
    }));

  const fetchEventUsers = useCallback(async () => {
    try {
      createQueryStringUrl(filterUsers);
      form.setFieldsValue(filterUsers);

      const response = await getEventUsers(
        id,
        filterUsers,
        currentPage,
        pageSize
      );

      setUsers(response.results);
      setTotalItems(response.total);
    } catch (error) {
      console.log("Error", error);
    }
  }, [currentPage, filterUsers, form, id, pageSize]);

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedUserIds(selectedRowKeys);
      setSelectedUsers(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
    selectedRowKeys: selectedUserIds,
  };

  const handleChangePermission = async (type, role) => {
    try {
      switch (type) {
        case ADD:
          setIsLoading((prevState) => ({ ...prevState, post: true }));
          const postParams = {
            userIds: selectedUserIds,
            eventId: id,
            type: role,
          };

          const postResponse = await postUserPermission(postParams);

          if (postResponse.status === 201) {
            notification.success({
              message: "Add permission successful",
            });
          }
          break;

        case REMOVE:
          setIsLoading((prevState) => ({ ...prevState, delete: true }));
          const deleteParams = {
            userIds: selectedUserIds,
            eventId: id,
          };

          const deleteResponse = await deleteUserPermission(deleteParams);

          if (deleteResponse.status === 200) {
            notification.success({
              message: "Remove permission successful",
            });
            handleToggleRemovePermissionModal();
          }
          break;

        default:
          break;
      }
      setSelectedUserIds([]);
      fetchEventUsers();
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsLoading((prevState) => ({
        ...prevState,
        delete: false,
        post: false,
      }));
    }
  };

  const handleSubmitFilter = (params) => {
    const formValues = form.getFieldsValue();

    setFilterUsers({
      ...filterUsers,
      keyWord: params?.keyWord || formValues?.keyWord || null,
      roles: params?.roles || formValues?.roles || null,
      permission: params?.permission || formValues?.permission || null,
      // startDate:
      //   params?.createdAt?.[0] && formValues?.createdAt?.[0]
      //     ? dayjs(params?.createdAt?.[0]).toISOString() ??
      //       dayjs(formValues?.createdAt?.[0]).toISOString()
      //     : null,
      // endDate:
      //   params?.createdAt?.[1] && formValues?.createdAt?.[1]
      //     ? dayjs(params?.createdAt?.[1]).toISOString() ??
      //       dayjs(formValues?.createdAt?.[1]).toISOString()
      //     : null,
    });

    setCurrentPage(1);
  };

  const handleResetFilter = () => {
    setFilterUsers({
      keyWord: null,
      roles: null,
      permission: null,
      // startDate: null,
      // endDate: null,
    });
    setCurrentPage(1);
  };

  const handleChangeRowColor = (record) => {
    if (record?.canCheckIn) {
      return "bg-gate-manage";
    }

    if (record?.canManage) {
      return "bg-event-manage";
    }
  };

  useEffect(() => {
    fetchEventUsers();
  }, [fetchEventUsers]);

  const tableColumns = [
    {
      key: "image",
      title: "Avatar",
      search: false,
      width: 100,
      render: (_, record) => {
        return <Avatar src={record?.avatar} />;
      },
    },
    {
      key: "keyWord",
      title: "Search",
      hideInTable: true,
      renderFormItem: (
        _,
        { type, defaultRender, formItemProps, fieldProps, ...rest },
        form
      ) => {
        if (type === "form") {
          return null;
        }
        return (
          <ProFormText
            name="searchFull"
            placeholder="Enter name, email or phone number"
          />
        );
      },
    },
    {
      key: "fullName",
      title: "Full name",
      dataIndex: "fullName",
      search: false,
      sorter: (current, next) => SortingText(current.fullName, next.fullName),
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      search: false,
      sorter: (current, next) => SortingText(current.email, next.email),
    },
    {
      key: "roles",
      title: "Roles",
      dataIndex: "roles",
      sorter: (current, next) => SortingText(current.roles, next.roles),
      width: 100,
      valueEnum: {
        USER: {
          text: "USER",
        },
        OPERATOR: {
          text: "OPERATOR",
        },
        GATE_OPERATOR: {
          text: "GATE_OPERATOR",
        },
        EVENT_OPERATOR: {
          text: "EVENT_OPERATOR",
        },
      },
    },
    {
      key: "permission",
      title: "Permission",
      valueEnum: {
        CHECK_IN: {
          text: "CHECK_IN",
        },
        EVENT_MANAGE: {
          text: "EVENT_MANAGE",
        },
      },
      render: (_, record) => {
        if (record?.canCheckIn) {
          return GATE_OPERATOR;
        }

        if (record?.canManage) {
          return EVENT_OPERATOR;
        }
      },
    },
    {
      key: "phoneNumber",
      title: "Phone",
      dataIndex: "phoneNumber",
      search: false,
      sorter: (current, next) =>
        SortingNumber(current.phoneNumber, next.phoneNumber),
    },
    {
      key: "address",
      title: "Address",
      dataIndex: "address",
      search: false,
      sorter: (current, next) => SortingText(current.address, next.address),
    },
    {
      key: "city",
      title: "City",
      dataIndex: "city",
      search: false,
      sorter: (current, next) => SortingDate(current.city, next.city),
    },
    {
      key: "nationality",
      title: "Nationality",
      dataIndex: "nationality",
      search: false,
      sorter: (current, next) =>
        SortingDate(current.nationality, next.nationality),
    },
    {
      key: "isVerify",
      title: "Is verify",
      search: false,
      width: 100,
      valueEnum: {
        TRUE: {
          text: "TRUE",
        },
        FALSE: {
          text: "FALSE",
        },
      },
      render: (_, record) => {
        if (record?.isVerify) {
          return (
            <CheckCircleOutlined
              style={{
                color: "green",
              }}
            />
          );
        }

        return (
          <MinusCircleOutlined
            style={{
              color: "red",
            }}
          />
        );
      },
    },
    {
      key: "createdAt",
      title: "Created At",
      valueType: "dateTimeRange",
      search: false,
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      sorter: (current, next) => SortingDate(current.createdAt, next.createdAt),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      key: "actions",
      title: "Actions",
      width: 100,
      search: false,
      render: (_, record) => [
        <div className="actions-wrapper">
          <Button
            key="details-event-user"
            className="icon-button"
            icon={<FileTextFilled />}
            onClick={() => navigate(`/users/${record?.id}`)}
          />
          <Button
            key="edit-event-user"
            className="icon-button"
            icon={<EditFilled />}
            onClick={() => navigate(`/users/${record?.id}/edit`)}
          />
        </div>,
      ],
    },
  ];

  return (
    <>
      <PageContainer title="All Users Of Event">
        {/* add permission modal */}
        {isShowModal.addPermission && (
          <AddPermissionModal
            isOpen={isShowModal.addPermission}
            handleOk={handleChangePermission}
            handleCancel={handleToggleAddPermissionModal}
          />
        )}

        {/* remove permission modal */}
        {isShowModal.removePermission && (
          <RemovePermissionModal
            isOpen={isShowModal.removePermission}
            handleOk={handleChangePermission}
            handleCancel={handleToggleRemovePermissionModal}
          />
        )}

        <ProTable
          rowKey="id"
          request={({ pageSize, current }, sort, filter) => {
            setPageSize(pageSize);

            return {
              // success,
              // total,
              filter,
            };
          }}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          onSubmit={(params) => handleSubmitFilter(params)}
          onReset={handleResetFilter}
          scroll={{ x: 1800, y: 500 }}
          columns={tableColumns}
          dataSource={users}
          rowClassName={(record) => handleChangeRowColor(record)}
          pagination={{
            pageSize: pageSize,
            total: totalItems,
            onChange: (page) => setCurrentPage(page),
          }}
          toolBarRender={() => [
            // <Button
            //   key="new-event-user"
            //   icon={<PlusOutlined />}
            //   onClick={() => {
            //     navigate(`/users/new`);
            //   }}
            //   type="primary"
            // >
            //   New User
            // </Button>,
            <Button
              key="add-permission"
              onClick={handleToggleAddPermissionModal}
              type="primary"
              disabled={selectedUserIds.length < 1}
              loading={isLoading.post}
            >
              Add permission
            </Button>,
            <Button
              key="remove-permission"
              onClick={handleToggleRemovePermissionModal}
              type="primary"
              danger
              disabled={
                selectedUserIds.length < 1 ||
                selectedUsers.every(
                  (record) => !record.canCheckIn && !record.canManage
                )
              }
              loading={isLoading.delete}
            >
              Remove permission
            </Button>,
          ]}
          search={{
            labelWidth: "auto",
            form: form,
          }}
        ></ProTable>
      </PageContainer>
    </>
  );
};

export default Permissions;
