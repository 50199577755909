import React, { useCallback, useEffect, useState } from "react";
import { Typography } from "antd";
import { ProTable, PageContainer } from "@ant-design/pro-components";
import dayjs from "dayjs";
import { useParams, useLocation } from "react-router";
import { SortingText, SortingDate } from "../../utils/sortingData";
import { getCheckInHistories } from "../../api";
import { useForm } from "antd/lib/form/Form";
import { parseQueryString } from "../../utils/parseQueryString";
import { createQueryStringUrl } from "../../utils/createQueryStringUrl";

const { Text } = Typography;

const CheckInHistories = () => {
  const { id } = useParams();
  const [form] = useForm();
  const { search } = useLocation();
  const queryString = parseQueryString(search);
  const customQueryString = {
    checkerName: queryString?.checkerName || null,
    phoneNumber: queryString?.phoneNumber || null,
    // startDate: queryString?.startDate || null,
    // endDate: queryString?.endDate || null,
  };

  const [checkInHistories, setCheckInHistories] = useState([]);
  const [filterCheckInHistories, setFilterCheckInHistories] =
    useState(customQueryString);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(null);

  const fetchCheckInHistories = useCallback(async () => {
    try {
      createQueryStringUrl(filterCheckInHistories);
      form.setFieldsValue(filterCheckInHistories);

      const response = await getCheckInHistories(
        id,
        filterCheckInHistories,
        currentPage,
        pageSize
      );

      setCheckInHistories(response.results);
      setTotalItems(response.total);
    } catch (error) {
      console.log("Error", error);
    }
  }, [currentPage, filterCheckInHistories, form, id, pageSize]);

  const handleSubmitFilter = (params) => {
    const formValues = form.getFieldsValue();

    setFilterCheckInHistories({
      ...filterCheckInHistories,
      checkerName: params?.checkerName || formValues?.checkerName || null,
      phoneNumber: params?.phoneNumber || formValues?.phoneNumber || null,
    });

    setCurrentPage(1);
  };

  const handleResetFilter = () => {
    setFilterCheckInHistories({
      checkerName: null,
      phoneNumber: null,
    });
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchCheckInHistories();
  }, [fetchCheckInHistories]);

  const tableColumns = [
    {
      key: "checkerName",
      title: "Checker name",
      dataIndex: ["checker", "fullName"],
      sorter: (current, next) =>
        SortingText(current.checker?.fullName, next.checker?.fullName),
    },
    {
      key: "userName",
      title: "User name",
      dataIndex: ["ticket", "user", "fullName"],
      search: false,
      sorter: (current, next) =>
        SortingText(
          current.ticket?.user?.fullName,
          next.ticket?.user?.fullName
        ),
    },
    {
      key: "userEmail",
      title: "User email",
      dataIndex: ["ticket", "user", "email"],
      search: false,
      sorter: (current, next) =>
        SortingText(current.ticket?.user?.email, next.ticket?.user?.email),
    },
    {
      key: "phoneNumber",
      title: "User phone number",
      dataIndex: ["ticket", "user", "phoneNumber"],
      sorter: (current, next) =>
        SortingText(
          current.ticket?.user?.phoneNumber,
          next.ticket?.user?.phoneNumber
        ),
    },

    {
      key: "ticketTypeEn",
      title: "English ticket type",
      dataIndex: ["ticket", "ticketType", "nameEn"],
      search: false,
      sorter: (current, next) =>
        SortingText(
          current.ticket?.ticketType?.nameEn,
          next.ticket?.ticketType?.nameEn
        ),
    },

    {
      key: "ticketTypeAr",
      title: "Arabic ticket type",
      dataIndex: ["ticket", "ticketType", "nameAr"],
      search: false,
      sorter: (current, next) =>
        SortingText(
          current?.ticket?.ticketType?.nameAr,
          next.ticket?.ticketType?.nameAr
        ),
    },
    {
      key: "createdAt",
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "dateTimeRange",
      search: false,
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      sorter: (current, next) => SortingDate(current.createdAt, next.createdAt),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      key: "updatedAt",
      title: "Updated At",
      dataIndex: "updatedAt",
      hideInTable: true,
      search: false,
      sorter: (current, next) => SortingDate(current.updatedAt, next.updatedAt),
      render: (_, record) => {
        return <Text>{dayjs(record.updatedAt).fromNow()}</Text>;
      },
    },
  ];

  return (
    <PageContainer title="Check in histories">
      <ProTable
        rowKey={["ticket", "ticketType", "nameAr"]}
        request={({ pageSize, current }, sort, filter) => {
          setPageSize(pageSize);

          return {
            checkInHistories,
            // success,
            // total,
            filter,
          };
        }}
        onSubmit={(params) => handleSubmitFilter(params)}
        onReset={handleResetFilter}
        scroll={{ x: 1400, y: 500 }}
        columns={tableColumns}
        dataSource={checkInHistories}
        pagination={{
          pageSize: pageSize,
          total: totalItems,
          onChange: (page) => setCurrentPage(page),
        }}
        search={{
          form: form,
          labelWidth: "auto",
        }}
      ></ProTable>
    </PageContainer>
  );
};

export default CheckInHistories;
