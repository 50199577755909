import React, { useCallback, useEffect, useState } from "react";
import {
  SortingDate,
  SortingNumber,
  SortingText,
} from "../../utils/sortingData";
import { Spin, Typography } from "antd";
import dayjs from "dayjs";
import { PageContainer, ProTable } from "@ant-design/pro-components";
import { useForm } from "antd/lib/form/Form";
import OrderLogDraw from "./OrderLogDraw";
import { createQueryStringUrl } from "../../utils/createQueryStringUrl";
import { useLocation } from "react-router-dom";
import { parseQueryString } from "../../utils/parseQueryString";
import useEventEnter from "../../utils/useEventEnter";
import { getOrderLogs } from "../../api";

const { Text } = Typography;

const OrderLogs = () => {
  const [form] = useForm();
  const { search } = useLocation();
  const queryString = parseQueryString(search);
  const customQueryString = {
    orderNumber: queryString.orderNumber || null,
    creatorName: queryString.creatorName || null,
    command: queryString.command || null,
  };

  const [orderLogs, setOrderLogs] = useState([]);
  const [filterOrderLogs, setFilterOrderLogs] = useState(customQueryString);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalItems: 0,
  });
  const [isShowOrderLogDraw, setIsShowOrderLogDraw] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const toggleOrderLogDraw = () =>
    setIsShowOrderLogDraw((prevState) => !prevState);

  const fetchOrderLogs = useCallback(async () => {
    setIsLoading(true);
    try {
      createQueryStringUrl(filterOrderLogs);
      form.setFieldsValue(filterOrderLogs);

      const response = await getOrderLogs(
        filterOrderLogs,
        pagination.currentPage,
        pagination.pageSize
      );

      setOrderLogs(response.results);
      setPagination((prevState) => ({
        ...prevState,
        totalItems: response.total,
      }));
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }, [filterOrderLogs, form, pagination.currentPage, pagination.pageSize]);

  const handleSubmitFilter = async (params) => {
    const formValues = form.getFieldsValue();

    setFilterOrderLogs({
      ...filterOrderLogs,
      orderNumber: params?.orderNumber || formValues?.orderNumber || null,
      creatorName: params?.creatorName || formValues?.creatorName || null,
      command: params?.command || formValues?.command || null,
    });

    setPagination((prevState) => ({ ...prevState, currentPage: 1 }));
  };

  const handleResetFilter = () => {
    setFilterOrderLogs({
      orderNumber: null,
      creatorName: null,
      command: null,
    });
    setPagination((prevState) => ({ ...prevState, currentPage: 1 }));
  };

  useEventEnter(handleSubmitFilter);

  useEffect(() => {
    fetchOrderLogs();
  }, [fetchOrderLogs]);

  const tableColumns = [
    {
      key: "orderNumber",
      title: "Order number",
      dataIndex: ["order", "orderNumber"],
      sorter: (current, next) =>
        SortingText(current.order.orderNumber, next.order.orderNumber),
    },
    {
      key: "creatorName",
      title: "Creator",
      dataIndex: ["creator", "fullName"],
      sorter: (current, next) =>
        SortingNumber(current.creator.fullName, next.creator.fullName),
    },
    {
      key: "command",
      title: "Command",
      dataIndex: "command",
      // sorter: (current, next) => SortingText(current.command, next.command),
      valueEnum: {
        UPDATE_ORDER: {
          text: "UPDATE_ORDER",
        },
      },
    },
    {
      key: "createdAt",
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "dateTimeRange",
      fieldProps: {
        placeholder: ["Start date", "End date"],
        // showTime: { format: "HH:mm" },
        showTime: false,
      },
      search: false,
      sorter: (current, next) => SortingDate(current.createdAt, next.createdAt),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record?.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
  ];

  return (
    <PageContainer title="All payment logs">
      <ProTable
        rowKey="id"
        loading={{
          indicator: <Spin className="spin-icon" />,
          spinning: isLoading,
        }}
        request={async ({ pageSize, current }, sort, filter) => {
          setPagination((prevState) => ({ ...prevState, pageSize }));

          return {
            orderLogs,
            // success,
            // total,
            filter,
          };
        }}
        onSubmit={(params) => handleSubmitFilter(params)}
        onReset={handleResetFilter}
        scroll={{ x: 800 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              toggleOrderLogDraw();
              setSelectedRow(record);
            },
          };
        }}
        columns={tableColumns}
        dataSource={orderLogs}
        search={{
          labelWidth: "auto",
          form: form,
        }}
        pagination={{
          pageSize: pagination.pageSize,
          total: pagination.totalItems,
          onChange: (page) =>
            setPagination((prevState) => ({ ...prevState, currentPage: page })),
        }}
      ></ProTable>

      {isShowOrderLogDraw && (
        <OrderLogDraw
          open={isShowOrderLogDraw}
          cancel={toggleOrderLogDraw}
          recordId={selectedRow.id}
        />
      )}
    </PageContainer>
  );
};

export default OrderLogs;
