import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageContainer, ProDescriptions } from "@ant-design/pro-components";
import { Button, Card, Divider } from "antd";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import * as timezone from "dayjs/plugin/timezone";
import { getSubCategory } from "../../../api";
import { COLORS } from "../../../utils/constant";

dayjs.extend(utc);
dayjs.extend(timezone);

const SubCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [subCategory, setSubCategory] = useState();
  const [colorName, setColorName] = useState();

  const fetchSubCategory = useCallback(async () => {
    try {
      const response = await getSubCategory(id);
      const color = COLORS.filter(
        (color) =>
          color.textColor === response.textColor &&
          color.backgroundColor === response.backgroundColor
      );

      setColorName(color[0].label);
      setSubCategory(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  useEffect(() => {
    fetchSubCategory();
  }, [fetchSubCategory]);

  return (
    <>
      {subCategory && (
        <PageContainer
          title={`Sub category details`}
          extra={[
            <Button
              key="edit-waiting-room"
              type="primary"
              onClick={() => navigate(`edit`)}
            >
              Edit sub category
            </Button>,
          ]}
        >
          <Card title="Sub category Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item
                label="Sub category ID"
                valueType="text"
                span={1}
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {subCategory?.id}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="English name" valueType="text">
                {subCategory?.nameEn}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Arabic name" valueType="text">
                {subCategory?.nameAr}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Category" valueType="text">
                {subCategory?.category}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <Card title="Related Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item label="Text color" valueType="text">
                {subCategory?.textColor}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Background color" valueType="text">
                {subCategory?.backgroundColor}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Color name" valueType="text">
                {colorName}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Created at">
                {dayjs(subCategory?.createdAt).format("YYYY-MM-DD HH:mm")}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>
        </PageContainer>
      )}
    </>
  );
};

export default SubCategory;
