import { Drawer, Modal, notification } from "antd";
import { useState } from "react";
import TicketTypeDetails from "..";
import EditTicketType from "../EditTicketType";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { deleteTicketType, patchTicketType } from "../../../../api";

const TicketTypeDraw = ({
  fetchTicketTypesByEventId,
  open,
  cancel = () => {},
  ticketData,
  ticketTypes,
  setTicketTypes,
}) => {
  const [isEditTicket, setIsEditTicket] = useState(false);
  const [isShowModal, setIsShowModal] = useState({
    delete: false,
    block: false,
  });

  const handleChangeDrawState = () => setIsEditTicket(true);

  const handleToggleDeleteModal = () =>
    setIsShowModal((prevState) => ({
      ...prevState,
      delete: !prevState.delete,
    }));

  const handleToggleBlockModal = () =>
    setIsShowModal((prevState) => ({ ...prevState, block: !prevState.block }));

  const handleSubmitDeleteModal = async () => {
    try {
      const response = await deleteTicketType(ticketData?.id);

      if (response.status === 200) {
        const customizeData = [...ticketTypes].filter(
          (ticketType) => ticketType.id !== response.data.id
        );

        setTicketTypes(customizeData);
        notification.success({ message: "Delete ticket type successfully!" });
      }
    } catch (error) {
      notification.error({ message: "Delete ticket type failed!" });
      console.log("Error", error);
    } finally {
      cancel();
      handleToggleDeleteModal();
    }
  };

  const handleSubmitBlockModal = async () => {
    try {
      const response = await patchTicketType(ticketData?.id, {
        isBlock: !ticketData.isBlock,
      });

      if (response.status === 200) {
        const customizeData = ticketTypes.map((ticketType) => {
          let newTicketType = { ...ticketType };

          if (ticketType.id === response.data.id) {
            newTicketType = {
              ...newTicketType,
              isBlock: response.data.isBlock,
            };
          }

          return newTicketType;
        });

        setTicketTypes(customizeData);
      }

      if (response.data.isBlock)
        return notification.success({
          message: "Block ticket type successfully!",
        });

      notification.success({ message: "Unblock ticket type successfully!" });
    } catch (error) {
      notification.error({ message: "Something went wrong!" });
      console.log("Error", error);
    } finally {
      cancel();
      handleToggleDeleteModal();
    }
  };

  return (
    <Drawer
      title="Ticket type details"
      width={700}
      closable={false}
      onClose={() => {
        setIsEditTicket(false);
        cancel();
      }}
      open={open}
    >
      {!isEditTicket ? (
        <TicketTypeDetails
          ticketId={ticketData?.id}
          ticketData={ticketData}
          handleChangeDrawState={handleChangeDrawState}
          handleShowDeleteModal={handleToggleDeleteModal}
          handleShowBlockModal={handleToggleBlockModal}
        />
      ) : (
        <EditTicketType
          fetchTicketTypesByEventId={fetchTicketTypesByEventId}
          cancel={cancel}
          ticketId={ticketData?.id}
        />
      )}

      {isShowModal.delete && (
        <Modal
          open={isShowModal.delete}
          onOk={handleSubmitDeleteModal}
          onCancel={handleToggleDeleteModal}
          centered
        >
          <div className="delete-content">
            <ExclamationCircleFilled style={{ fontSize: "48px" }} />

            <p>Are you sure to delete this record?</p>
          </div>
        </Modal>
      )}

      {isShowModal.block && (
        <Modal
          open={isShowModal.block}
          onOk={handleSubmitBlockModal}
          onCancel={handleToggleBlockModal}
          centered
        >
          <div className="delete-content">
            <ExclamationCircleFilled style={{ fontSize: "48px" }} />

            <p>Are you sure to block this record?</p>
          </div>
        </Modal>
      )}
    </Drawer>
  );
};

export default TicketTypeDraw;
