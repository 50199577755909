export const parseQueryString = (string) => {
  if (string === "" || string == null) return {};

  if (string.charAt(0) === "?") string = string.slice(1);

  let entries = string.split("&"),
    counters = {},
    filterObject = {};

  for (let i = 0; i < entries.length; i++) {
    let entry = entries[i].split("=");
    let fieldName = decodeURIComponent(entry[0]);
    let fieldValue = entry.length === 2 ? decodeURIComponent(entry[1]) : "";

    if (fieldValue.toLowerCase() === "true") fieldValue = "TRUE";
    else if (fieldValue.toLowerCase() === "false") fieldValue = "FALSE";

    let levels = fieldName.split(/\]\[?|\[/);
    let cursor = filterObject;

    if (fieldName.indexOf("[") > -1) levels.pop();

    for (let j = 0; j < levels.length; j++) {
      let level = levels[j],
        nextLevel = levels[j + 1];
      let isNumber = nextLevel === "" || !isNaN(parseInt(nextLevel, 10));

      if (level === "") {
        let fieldName = levels.slice(0, j).join();
        if (counters[fieldName] == null) {
          counters[fieldName] = Array.isArray(cursor) ? cursor.length : 0;
        }
        level = counters[fieldName]++;
      }

      // Disallow direct prototype pollution
      else if (level === "__proto__") break;
      if (j === levels.length - 1) cursor[level] = fieldValue;
      else {
        // Read own properties exclusively to disallow indirect
        // prototype pollution
        let desc = Object.getOwnPropertyDescriptor(cursor, level);
        if (desc !== null) desc = desc.value;
        if (desc === null) cursor[level] = desc = isNumber ? [] : {};
        cursor = desc;
      }
    }
  }

  return filterObject;
};
