import { PlusOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Typography, Button, Modal } from "antd";
import { ProTable, PageContainer } from "@ant-design/pro-components";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import {
  DeleteFilled,
  FileTextFilled,
  EditFilled,
  ExclamationCircleFilled,
  UndoOutlined,
} from "@ant-design/icons";
import {
  SortingText,
  SortingDate,
  SortingNumber,
} from "../../../utils/sortingData";
import { filterDateTimeBetween, filterText } from "../../../utils/filterData";
import { deleteSlider, getSliders } from "../../../api";

const { Text } = Typography;

const Sliders = () => {
  const navigate = useNavigate();

  const [sliders, setSliders] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState();
  const [isShowModal, setIsShowModal] = useState(false);
  const [filterSliders, setFilterSliders] = useState(sliders);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(null);

  const showModal = (record) => {
    setSelectedRecord(record);
    setIsShowModal(true);
  };

  const handleCancel = () => {
    setIsShowModal(false);
  };

  const fetchSliders = useCallback(async () => {
    try {
      const response = await getSliders(currentPage, pageSize);

      setFilterSliders(response.results);
      setSliders(response.results);
      setTotalItems(response.total);
    } catch (err) {
      console.log("err");
    }
  }, [currentPage, pageSize]);

  const handleSubmitModal = async () => {
    try {
      const response = await deleteSlider(selectedRecord?.id);

      if (response.status === 200) {
        await fetchSliders();
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      handleCancel();
    }
  };

  const handleSubmitFilter = (params) => {
    const newParams = {
      ...params,
      nameEn: params?.nameEn || "",
      nameAr: params?.nameAr || "",
      startTime: params?.createdAt?.[0],
      endTime: params?.createdAt?.[1],
    };

    const newFilterClubs = filterSliders.filter((club) => {
      const filterEnglishName = filterText(club?.nameEn, newParams?.nameEn);
      const filterArabicName = filterText(club?.nameAr, newParams?.nameAr);
      const filterCreatedAt = filterDateTimeBetween(
        club?.createdAt,
        newParams?.startTime,
        newParams?.endTime
      );

      return filterEnglishName && filterArabicName && filterCreatedAt;
    });

    setSliders(newFilterClubs);
    setPageSize(1);
  };

  const handleResetFilter = () => setSliders(filterSliders);

  useEffect(() => {
    fetchSliders();
  }, [fetchSliders]);

  const tableColumns = [
    {
      key: "image",
      title: "Image",
      search: false,
      render: (_, record) => {
        return <Avatar src={record?.url} />;
      },
    },
    {
      key: "creator",
      title: "Creator",
      dataIndex: ["creator", "fullName"],
      search: false,
      sorter: (current, next) =>
        SortingText(current.creator?.fullName, next.creator?.fullName),
    },
    {
      key: "position",
      title: "Image position",
      dataIndex: "position",
      sorter: (current, next) => SortingNumber(current.position, next.position),
    },
    {
      key: "createdAt",
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "dateTimeRange",
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      sorter: (current, next) => SortingDate(current.createdAt, next.createdAt),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      key: "updatedAt",
      title: "Updated At",
      dataIndex: "updatedAt",
      hideInTable: true,
      search: false,
      sorter: (current, next) => SortingDate(current.updatedAt, next.updatedAt),
      render: (_, record) => {
        return <Text>{dayjs(record.updatedAt).fromNow()}</Text>;
      },
    },
    {
      key: "actions",
      title: "Actions",
      search: false,
      width: 100,
      render: (_, record) => [
        <div key={record.id} className="actions-wrapper">
          <Button
            className="icon-button"
            icon={<FileTextFilled />}
            onClick={() => navigate(`/sliders/${record?.id}`)}
          />

          <Button
            className="icon-button"
            icon={<EditFilled />}
            onClick={() => navigate(`/sliders/${record?.id}/edit`)}
          />

          {record?.deleted ? (
            <Button
              className="icon-button"
              icon={<UndoOutlined />}
              onClick={() => showModal(record)}
            />
          ) : (
            <Button
              className="icon-button"
              icon={<DeleteFilled />}
              onClick={() => showModal(record)}
            />
          )}
        </div>,
      ],
    },
  ];

  return (
    <PageContainer title="All Sliders">
      <Modal
        open={isShowModal}
        onOk={() => handleSubmitModal()}
        onCancel={handleCancel}
        centered
      >
        <div className="delete-content">
          <ExclamationCircleFilled style={{ fontSize: "48px" }} />
          <p>Are you sure to delete this record?</p>
        </div>
      </Modal>

      <ProTable
        rowKey="id"
        request={({ pageSize, current }, sort, filter) => {
          setPageSize(pageSize);

          return {
            sliders,
            // success,
            // total,
            filter,
          };
        }}
        onSubmit={(params) => handleSubmitFilter(params)}
        onReset={handleResetFilter}
        // onRow={(record) => {
        //   return {
        //     onClick: () => {
        //       navigate(`${record?.id}`);
        //     },
        //   };
        // }}
        scroll={{ x: 1000, y: 500 }}
        columns={tableColumns}
        dataSource={sliders}
        pagination={{
          pageSize: pageSize,
          total: totalItems,
          onChange: (page) => setCurrentPage(page),
        }}
        toolBarRender={() => [
          <Button
            key="button"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate("/sliders/new");
            }}
            type="primary"
          >
            New Slider
          </Button>,
        ]}
        search={false}
      ></ProTable>
    </PageContainer>
  );
};

export default Sliders;
