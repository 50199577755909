import { PageContainer, ProDescriptions } from "@ant-design/pro-components";
import { Button, Card, notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { getOdooWaiting, postSyncAllToOdoo } from "../../../api";

const Odoo = () => {
  const [odooWaiting, setOdooWaiting] = useState({
    order: null,
    ticket: null,
  });

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement, type) => {
    switch (type) {
      case 1:
        api.error({
          message: "Update settings",
          description: "Something went wrong!",
          placement,
          duration: 3,
        });
        break;

      default:
        api.success({
          message: "Update settings",
          description: "Sync all to Odoo successfully.",
          placement,
          duration: 3,
        });
        break;
    }
  };

  const fetchOdooWaiting = useCallback(async () => {
    try {
      const response = await getOdooWaiting();

      if (response) {
        setOdooWaiting(response);
      }
    } catch (error) {
      console.log("Error", error);
    }
  }, []);

  const handleSyncAllToOdoo = async () => {
    let response = null;
    try {
      response = await postSyncAllToOdoo();

      if (response.status === 200) {
        openNotification("bottomLeft");
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      if (!response) {
        openNotification("bottomLeft", 1);
      }
    }
  };

  useEffect(() => {
    fetchOdooWaiting();
  }, [fetchOdooWaiting]);

  return (
    <PageContainer
      title={`Odoo`}
      extra={[
        <Button
          key="sync-all-odoo"
          type="primary"
          onClick={handleSyncAllToOdoo}
        >
          Sync all to Odoo
        </Button>,
      ]}
    >
      {contextHolder}

      <Card title="Waiting">
        <ProDescriptions column={1}>
          <ProDescriptions.Item
            label="Order"
            valueType="text"
            span={1}
            contentStyle={{
              maxWidth: "80%",
            }}
          >
            {odooWaiting.order}
          </ProDescriptions.Item>

          <ProDescriptions.Item label="Ticket" valueType="text">
            {odooWaiting.ticket}
          </ProDescriptions.Item>
        </ProDescriptions>
      </Card>
    </PageContainer>
  );
};

export default Odoo;
