import {
  ProForm,
  ProFormText,
  PageContainer,
} from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import ImageUpload from "../../../components/UploadImage";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSponsorData, patchSponsor } from "../../../api/sponsors";
import { notification } from "antd";
import { PAGES } from "../../../utils/constant";
import { useForm } from "antd/lib/form/Form";

const EditSponsor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = useForm();

  const [sponsorData, setSponsorData] = useState();
  const [sponsorLogo, setSponsorLogo] = useState();
  console.log("🚀 ~ sponsorLogo:", sponsorLogo);

  const fetchSponsorData = useCallback(async () => {
    try {
      const response = await getSponsorData(id);

      setSponsorData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  const handleSetImage = (value, key, callBack) => {
    form.setFieldValue(key, value);

    callBack((prevState) => ({ ...prevState, [key]: value.url }));
    form.validateFields([key]);
  };

  const handleSubmit = async (values) => {
    try {
      const newParams = {
        ...values,
        logo: sponsorLogo?.logo ?? sponsorData?.logo,
      };

      const response = await patchSponsor(id, newParams);

      if (response.status === 200) {
        notification.success({ message: "Update sponsor successful" });
        navigate("/sponsors");
      }
    } catch (error) {
      notification.error({ message: "Update sponsor failed" });
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchSponsorData();
  }, [fetchSponsorData]);

  return (
    <PageContainer title="Edit sponsor">
      {sponsorData && (
        <ProForm
          form={form}
          initialValues={{
            title: sponsorData?.title,
            logo: sponsorData?.logo,
          }}
          name="createClubForm"
          layout="horizontal"
          onFinish={handleSubmit}
        >
          <ProForm.Group>
            <div className="form-fields-wrapper">
              <ProFormText
                name="title"
                width={"lg"}
                label="Sponsor Name"
                placeholder="Enter sponsor name in Arabic"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Sponsor name is required! My friends
                      </p>
                    ),
                  },
                ]}
              />
            </div>
          </ProForm.Group>

          <ProForm.Group>
            <ProForm.Item
              label="Logo"
              name="logo"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Logo is required! My friends
                    </p>
                  ),
                },
              ]}
            >
              <ImageUpload
                initialValue={sponsorData?.logo}
                callBackGetUrl={(value) =>
                  handleSetImage(value, "logo", setSponsorLogo)
                }
                type={PAGES.SPONSOR}
              />
            </ProForm.Item>
          </ProForm.Group>
        </ProForm>
      )}
    </PageContainer>
  );
};

export default EditSponsor;
