import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageContainer, ProDescriptions } from "@ant-design/pro-components";
import dayjs from "dayjs";
import { Card, Divider } from "antd";
import { getTicketData } from "../../../../api";

const TicketDetails = () => {
  const { id } = useParams();

  const [ticketData, setTicketData] = useState();

  const fetchTicketData = useCallback(async () => {
    try {
      const response = await getTicketData(id);

      setTicketData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  useEffect(() => {
    fetchTicketData();
  }, [fetchTicketData]);

  return (
    <>
      {ticketData && (
        <PageContainer title={`Ticket details`}>
          <Card title="Ticket Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item
                label="Ticket ID"
                valueType="text"
                span={1}
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {ticketData?.id}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Price" valueType="text">
                {ticketData?.price}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Status" valueType="text">
                {ticketData?.status}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Payment status" valueType="text">
                {ticketData?.paymentStatus}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Created at">
                {dayjs(ticketData?.createdAt).format("YYYY-MM-DD HH:mm")}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <Card title="Related Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item label="Event ID" valueType="text">
                {ticketData?.eventId}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Order ID" valueType="text">
                {ticketData?.orderId}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Order Number" valueType="text">
                {ticketData?.order?.orderNumber}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Ticket number" valueType="text">
                {ticketData?.ticketNumber}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Ticket type ID" valueType="text">
                {ticketData?.ticketTypeId}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <Card title="User Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item label="ID" valueType="text">
                {ticketData?.user?.id}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Name" valueType="text">
                {ticketData?.user?.fullName}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Email" valueType="text">
                {ticketData?.user?.email}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Phone Number" valueType="text">
                {ticketData?.user?.phoneNumber}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>
        </PageContainer>
      )}
    </>
  );
};

export default TicketDetails;
