import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  Modal,
  Pagination,
  Row,
} from "antd";
import { getAssignTicketTypeForCoupon } from "../../api";

const ModalAssignTicketTypes = ({
  ticketTypes,
  setTicketTypeName,
  setPage,
  total,
  setCheckedList,
  checkedList,
  idCoupon,
  fetchCoupons,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const CheckboxGroup = Checkbox.Group;

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    if (idCoupon) {
      const params = {
        ticketTypeIds: checkedList,
      };
      try {
        await getAssignTicketTypeForCoupon(idCoupon, params);
        fetchCoupons();
      } catch (error) {
        console.log("🚀 ~ handleOk ~ error:", error);
      }
    }

    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // const onCheckAllChange = (e) => {
  //   setCheckedList(e.target.checked ? nameTicketType : []);
  // };

  // const handleChoiceTicketType = (item) => {
  //   setCheckedList(item.target.value);
  // };

  const handleChoiceTicketType = (event, id) => {
    if (event?.target?.checked) {
      setCheckedList([...checkedList, id]);
    } else {
      const removeId = [...checkedList].filter((obj) => obj !== id);
      setCheckedList(removeId);
    }
  };

  const handleFilterTicketType = (e) => {
    setTicketTypeName(e.target.value);
  };

  const handlePage = (e) => {
    setPage(e);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Assign Ticket Type
      </Button>
      <Modal
        title="List ticket types"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row>
          <Col span={8}>
            {/* <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              Choice all
            </Checkbox> */}
          </Col>
          <Col span={16}>
            <Input
              onChange={handleFilterTicketType}
              placeholder="Ticket type"
            />
          </Col>
        </Row>
        <Divider />

        <Row>
          {ticketTypes.map((item) => (
            <Col span={12}>
              <Checkbox
                onChange={(e) => handleChoiceTicketType(e, item?.id)}
                checked={checkedList?.some((check) => check === item?.id)}
                value={item?.id}
              >
                {item?.event.nameAr}'--'
                {item?.nameEn}
              </Checkbox>
            </Col>
          ))}
        </Row>

        <Divider />

        <Pagination defaultCurrent={1} onChange={handlePage} total={total} />
      </Modal>
    </>
  );
};
export default ModalAssignTicketTypes;
