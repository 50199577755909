import {
  ProForm,
  ProFormText,
  PageContainer,
  ProFormSelect,
} from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import { postSubCategories } from "../../../api";
import { COLORS, EVENT_TYPES } from "../../../utils/constant";

const NewSubCategory = () => {
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      const newParams = {
        category: values?.category,
        nameAr: values?.nameAr,
        nameEn: values?.nameEn,
        backgroundColor: values?.color?.backgroundColor,
        textColor: values?.color?.textColor,
      };

      const response = await postSubCategories(newParams);

      if (response.status === 201) {
        navigate("/sub-categories");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <PageContainer title="Create new sub category">
      <ProForm
        name="subCategoryForm"
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <ProForm.Group>
          <div className="form-fields-wrapper">
            <ProFormText
              name="nameEn"
              label="English Name"
              width={"lg"}
              placeholder="Enter sub category name in English"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      English name is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormText
              name="nameAr"
              width={"lg"}
              label="Arabic Name"
              placeholder="Enter sub category name in Arabic"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Arabic name is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormSelect
              name="category"
              width={"lg"}
              label="Category"
              placeholder="Select category"
              options={EVENT_TYPES}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Select category is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormSelect
              label="Color"
              name="color"
              width={"lg"}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Country is required! My friends
                    </p>
                  ),
                },
              ]}
              placeholder="Select color"
              options={COLORS}
              fieldProps={{
                labelInValue: true,
                optionItemRender: (item) => {
                  return (
                    <div
                      style={{
                        backgroundColor: item.backgroundColor,
                        color: item.textColor,
                        padding: "0px 8px",
                        borderRadius: 4,
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                        }}
                      >
                        {item.label}
                      </p>
                    </div>
                  );
                },
              }}
              showSearch
            />
          </div>
        </ProForm.Group>
      </ProForm>
    </PageContainer>
  );
};

export default NewSubCategory;
