import React, { useCallback, useEffect, useState } from "react";
import { Typography, Button, Modal, Spin, Avatar } from "antd";
import {
  ProTable,
  PageContainer,
  ProFormText,
} from "@ant-design/pro-components";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import {
  FileTextFilled,
  EditFilled,
  CheckCircleOutlined,
  MinusCircleOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import {
  SortingDate,
  SortingNumber,
  SortingText,
} from "../../utils/sortingData";
import useEventEnter from "../../utils/useEventEnter";
import { useForm } from "antd/lib/form/Form";
import { getUsers, exportUsers } from "../../api";
import { useLocation } from "react-router-dom";
import { parseQueryString } from "../../utils/parseQueryString";
import { createQueryStringUrl } from "../../utils/createQueryStringUrl";

const { Text } = Typography;

const Users = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { search } = useLocation();
  const queryString = parseQueryString(search);
  const customQueryString = {
    keyWord: queryString?.keyWord || null,
    roles: queryString?.roles || null,
    // startDate: queryString?.startDate || null,
    // endDate: queryString?.endDate || null,
  };

  const [users, setUsers] = useState([]);
  const [filterUsers, setFilterUsers] = useState(customQueryString);
  const [isShowExportModal, setIsShowExportModal] = useState(false);
  const [totalItems, setTotalItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  // const handleShowExportModal = () => setIsShowExportModal(true);
  const handleCloseExportModal = () => setIsShowExportModal(false);

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      createQueryStringUrl(filterUsers);
      form.setFieldsValue(filterUsers);

      const response = await getUsers(filterUsers, currentPage, pageSize);

      setUsers(response?.results);
      setTotalItems(response?.total);
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, filterUsers, form, pageSize]);

  const handleSubmitFilter = (params) => {
    const formValues = form.getFieldsValue();

    setFilterUsers({
      ...filterUsers,
      keyWord: params?.keyWord || formValues?.keyWord || null,
      roles: params?.roles || formValues?.roles || null,
      // startDate:
      //   params?.createdAt?.[0] && formValues?.createdAt?.[0]
      //     ? dayjs(params?.createdAt?.[0]).toISOString() ??
      //       dayjs(formValues?.createdAt?.[0]).toISOString()
      //     : null,
      // endDate:
      //   params?.createdAt?.[1] && formValues?.createdAt?.[1]
      //     ? dayjs(params?.createdAt?.[1]).toISOString() ??
      //       dayjs(formValues?.createdAt?.[1]).toISOString()
      //     : null,
    });

    setCurrentPage(1);
  };

  const handleResetFilter = () => {
    setFilterUsers({
      keyWord: null,
      roles: null,
      // startDate: null,
      // endDate: null,
    });
    setCurrentPage(1);
  };

  const handleExportToExcel = useCallback(async () => {
    setIsShowExportModal(false);

    try {
      const response = await exportUsers();
      const blob = response.data;
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "users.xlsx";
      link.style.display = "none";
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.log("Export Excel Fail With Error---->", error);
    }
  }, []);

  useEventEnter(handleSubmitFilter);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const tableColumns = [
    {
      key: "avatar",
      title: "Avatar",
      dataIndex: "avatar",
      search: false,
      width: 100,
      render: (_, record) => {
        return <Avatar src={record?.avatar} />;
      },
    },
    {
      key: "keyWord",
      title: "Search",
      hideInTable: true,
      renderFormItem: (
        _,
        { type, defaultRender, formItemProps, fieldProps, ...rest },
        form
      ) => {
        if (type === "form") {
          return null;
        }
        return (
          <ProFormText
            name="searchFull"
            placeholder="Enter name, email or phone number"
          />
        );
      },
    },
    {
      key: "fullName",
      title: "Full name",
      dataIndex: "fullName",
      search: false,
      sorter: (current, next) => SortingText(current.fullName, next.fullName),
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      search: false,
      sorter: (current, next) => SortingText(current.email, next.email),
    },
    {
      key: "roles",
      title: "Roles",
      dataIndex: "roles",
      sorter: (current, next) => SortingText(current.roles, next.roles),
      width: 100,
      valueEnum: {
        USER: {
          text: "USER",
        },
        OPERATOR: {
          text: "OPERATOR",
        },
        GATE_OPERATOR: {
          text: "GATE_OPERATOR",
        },
        EVENT_OPERATOR: {
          text: "EVENT_OPERATOR",
        },
      },
    },
    {
      key: "phoneNumber",
      title: "Phone",
      dataIndex: "phoneNumber",
      width: 150,
      search: false,
      sorter: (current, next) =>
        SortingNumber(current.phoneNumber, next.phoneNumber),
    },
    {
      key: "address",
      title: "Address",
      dataIndex: "address",
      search: false,
      sorter: (current, next) => SortingText(current.address, next.address),
    },
    {
      key: "city",
      title: "City",
      dataIndex: "city",
      search: false,
      sorter: (current, next) => SortingDate(current.city, next.city),
    },
    {
      key: "nationality",
      title: "Nationality",
      dataIndex: "nationality",
      search: false,
      sorter: (current, next) =>
        SortingDate(current.nationality, next.nationality),
    },
    {
      key: "isVerify",
      title: "Is verify",
      dataIndex: "isVerify",
      width: 100,
      search: false,
      valueEnum: {
        TRUE: {
          text: "TRUE",
        },
        FALSE: {
          text: "FALSE",
        },
      },
      render: (_, record) => {
        if (record?.isVerify) {
          return (
            <CheckCircleOutlined
              style={{
                color: "green",
              }}
            />
          );
        }

        return (
          <MinusCircleOutlined
            style={{
              color: "red",
            }}
          />
        );
      },
    },
    {
      key: "createdAt",
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "dateTimeRange",
      search: false,
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      sorter: (current, next) => SortingDate(current.createdAt, next.createdAt),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      key: "actions",
      title: "Actions",
      width: 100,
      search: false,
      render: (_, record) => [
        <div className="actions-wrapper">
          <Button
            className="icon-button"
            icon={<FileTextFilled />}
            onClick={() => navigate(`/users/${record?.id}`)}
          />
          <Button
            className="icon-button"
            icon={<EditFilled />}
            onClick={() => navigate(`/users/${record?.id}/edit`)}
          />
        </div>,
      ],
    },
  ];

  return (
    <PageContainer title="All Users">
      <Modal
        open={isShowExportModal}
        onOk={handleExportToExcel}
        onCancel={handleCloseExportModal}
        centered
      >
        <div className="delete-content">
          <ExclamationCircleFilled style={{ fontSize: "48px" }} />
          <p>Do you want to export users data to excel?</p>
        </div>
      </Modal>

      <ProTable
        rowKey="id"
        loading={{
          indicator: <Spin className="spin-icon" spin="true" />,
          spinning: isLoading,
        }}
        scroll={{ x: 1800, y: 500 }}
        request={({ pageSize, current }, sort, filter) => {
          console.log("submit filter", filter);
          console.log("submit pageSize", pageSize);
          console.log("submit current", current);
          setPageSize(pageSize);

          return {
            // success,
            // total,
            filter,
          };
        }}
        onSubmit={(params) => handleSubmitFilter(params)}
        onReset={handleResetFilter}
        // onRow={(record) => {
        //   return {
        //     onClick: () => {
        //       navigate(`${record?.id}`);
        //     },
        //   };
        // }}
        columns={tableColumns}
        dataSource={users}
        toolBarRender={() => [
          // <Button
          //   key="button"
          //   icon={<PlusOutlined />}
          //   onClick={() => {
          //     navigate("/users/new");
          //   }}
          //   type="primary"
          // >
          //   New User
          // </Button>,
          // <Button
          //   key="button"
          //   icon={<DownloadOutlined />}
          //   onClick={() => handleShowExportModal()}
          //   type="primary"
          // >
          //   Export excel
          // </Button>,
        ]}
        search={{
          labelWidth: "auto",
          form: form,
        }}
        pagination={{
          pageSize: pageSize,
          total: totalItems,
          onChange: (page) => setCurrentPage(page),
        }}
      ></ProTable>
    </PageContainer>
  );
};

export default Users;
