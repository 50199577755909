import {
  PageContainer,
  ProForm,
  ProFormSelect,
} from "@ant-design/pro-components";
import { useForm } from "antd/lib/form/Form";
import React, { useCallback, useEffect, useState } from "react";
import { CustomizeOptionsByConstant } from "../../../../utils/customizeOptions";
import {
  TICKET_PAYMENT_STATUS,
  TICKET_STATUS,
} from "../../../../utils/constant";
import { useNavigate, useParams } from "react-router-dom";
import { getTicketData, patchEventTicket } from "../../../../api";

const EditEventTicket = () => {
  const { id } = useParams();
  const [form] = useForm();
  const navigate = useNavigate();

  const [ticketData, setTicketData] = useState(null);

  const fetchTicketData = useCallback(async () => {
    try {
      const response = await getTicketData(id);

      setTicketData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  const handleSubmit = async (values) => {
    try {
      const newParams = {
        ...values,
      };

      const response = await patchEventTicket(id, newParams);

      if (response.status === 200) {
        navigate(-1);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchTicketData();
  }, [fetchTicketData]);

  return (
    <PageContainer title="Edit ticket">
      {ticketData && (
        <ProForm
          form={form}
          name="editEventForm"
          layout="horizontal"
          onFinish={handleSubmit}
          className="create-event-form event-form"
          initialValues={{
            ...ticketData,
          }}
          onFinishFailed={(values) => {
            console.log("🚀 ~ values:", values);
          }}
          scrollToFirstError
        >
          <ProForm.Group>
            <div className="form-fields-wrapper">
              <ProFormSelect
                label="Ticket status"
                name="status"
                width={"lg"}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Ticket status is required! My friends
                      </p>
                    ),
                  },
                ]}
                placeholder="Select option"
                options={CustomizeOptionsByConstant(TICKET_STATUS)}
                showSearch
              />

              <ProFormSelect
                label="Payment status"
                name="paymentStatus"
                width={"lg"}
                placeholder="Select option"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Payment status is required! My friends
                      </p>
                    ),
                  },
                ]}
                options={CustomizeOptionsByConstant(TICKET_PAYMENT_STATUS)}
              />
            </div>
          </ProForm.Group>
        </ProForm>
      )}
    </PageContainer>
  );
};

export default EditEventTicket;
