export const CustomizeOptionsByApi = (data) => {
  return data.map((item) => ({ value: item.id, label: item.nameEn }));
};

export const CustomizeOptionsByNameAr = (data) => {
  return data.map((item) => ({ value: item.id, label: item.nameAr }));
};

export const CustomizeOptionsByName = (data) => {
  return data.map((item) => ({
    value: item.id,
    label: `${item.nameEn} - ${item.nameAr}`,
  }));
};

export const CustomizeOptionsByConstant = (data) => {
  return data.map((item) => ({ value: item, label: item }));
};

export const CustomizeCountries = (data) => {
  // let regionNames = new Intl.DisplayNames(["en"], { type: "region" });

  return data.map((item) => ({
    value: item.countryCode,
    label: item.countryName,
  }));
};
