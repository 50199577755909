import {
  ProForm,
  ProFormText,
  PageContainer,
  ProFormDateTimePicker,
  ProFormSelect,
  ProFormMoney,
} from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import * as dayjs from "dayjs";
import { Badge, Divider, Row, notification } from "antd";
import { getListTicketType, postCoupon } from "../../../api";
import ModalAssignTicketTypes from "../ModalAssignTicketType";

const CouponType = [
  { value: "PERCENTAGE", label: "PERCENTAGE" },
  { value: "FIXED_AMOUNT", label: "FIXED AMOUNT" },
];

const CouponNew = () => {
  const [form] = useForm();
  const navigate = useNavigate();

  const [ticketTypes, setTicketTypes] = useState([]);
  const [ticketTypeName, setTicketTypeName] = useState("");
  const [page, setPage] = useState(1);
  const limit = 10;
  const [total, setTotal] = useState();
  const [checkedListIdType, setCheckedListIdType] = useState([]);
  const [ticketTypeAdded, setTicketTypeAdded] = useState([]);

  const fetchTicketTypes = useCallback(async () => {
    const params = {
      page: page,
      limit: limit,
      ticketTypeName: ticketTypeName,
    };
    try {
      const response = await getListTicketType(params);

      setTicketTypes(response?.results);
      setTotal(response?.total);
    } catch (error) {
      console.log("Error", error);
    }
  }, [ticketTypeName, page, limit]);

  const handleSubmit = async (values) => {
    try {
      const newParams = {
        ...values,
        ticketTypeIds: checkedListIdType,
        startDate: values.startDate
          ? dayjs
              .tz(values.startDate, "YYYY-MM-DD HH:mm", "Asia/Riyadh")
              .utc()
              .format()
          : null,
        endDate: values.endDate
          ? dayjs
              .tz(values.endDate, "YYYY-MM-DD HH:mm", "Asia/Riyadh")
              .utc()
              .format()
          : null,
      };

      const response = await postCoupon(newParams);

      if (response.status === 201) {
        navigate(`/coupon`);
        notification.success({
          message: "Create coupon successful! My friends",
        });
      }
    } catch (error) {
      notification.error({
        message: "Create coupon failed! My friends",
      });
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchTicketTypes();
  }, [fetchTicketTypes]);

  useEffect(() => {
    const mySet = new Set(checkedListIdType);

    const newCourses = ticketTypes?.filter((item) => mySet.has(item.id)) || [];
    setTicketTypeAdded(newCourses);
  }, [checkedListIdType, ticketTypes]);

  return (
    <PageContainer title="Create new coupon">
      <ProForm
        form={form}
        initialValues={{
          ...form.getFieldsValue(),
          metaData: {
            guestScore: 0,
            hostScore: 0,
          },
        }}
        name="createEventForm"
        layout="horizontal"
        onFinish={handleSubmit}
        className="create-event-form event-form"
        onFinishFailed={({ values }) => {
          console.log("🚀 ~ values:", values);
        }}
      >
        <ProForm.Group>
          <div className="form-fields-wrapper">
            <div className="mb-4">
              <ModalAssignTicketTypes
                ticketTypes={ticketTypes}
                setTicketTypeName={setTicketTypeName}
                setPage={setPage}
                total={total}
                setCheckedList={setCheckedListIdType}
                checkedList={checkedListIdType}
              />
            </div>

            <Row>
              {ticketTypeAdded?.map((item) => (
                <Badge count={item?.nameEn} />
              ))}
            </Row>
            <Divider />

            <ProFormSelect
              label="Choose Discount Type"
              name="discountType"
              width={"lg"}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Discount Type is required! My friends
                    </p>
                  ),
                },
              ]}
              placeholder="Select Discount Type"
              options={CouponType}
              showSearch
            />

            <ProFormText
              name="couponName"
              width={"lg"}
              label="Coupon Name"
              placeholder="Enter Coupon Name"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Coupon Name is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormMoney
              name="limitUser"
              width={"lg"}
              label="Limit User"
              placeholder="Enter Limit User"
              fieldProps={{
                moneySymbol: false,
              }}
              min={1}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Limit User is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormMoney
              name="discountAmount"
              width={"lg"}
              label="Discount Amount"
              placeholder="Enter Discount Amount"
              fieldProps={{
                moneySymbol: false,
              }}
              min={1}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Discount Amount is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormDateTimePicker
              name="startDate"
              tooltip="This always set to Saudi Arabia Timezone"
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              width={"lg"}
              label="Start on"
              placeholder="Choose start on"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Start on is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormDateTimePicker
              name="endDate"
              tooltip="This always set to Saudi Arabia Timezone"
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              width={"lg"}
              label="End On"
              placeholder="Choose End On"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      End On is required! My friends
                    </p>
                  ),
                },
              ]}
            />
          </div>
        </ProForm.Group>
      </ProForm>
    </PageContainer>
  );
};

export default CouponNew;
