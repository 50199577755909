import {
  ProForm,
  ProFormText,
  PageContainer,
} from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import ImageUpload from "../../../components/UploadImage";
import { useState } from "react";
import { postSponsor } from "../../../api/sponsors";
import { notification } from "antd";
import { PAGES } from "../../../utils/constant";
import { useForm } from "antd/lib/form/Form";

const NewSponsor = () => {
  const navigate = useNavigate();
  const [form] = useForm();

  const [sponsorLogo, setSponsorLogo] = useState();

  const handleSetImage = (value, key, callBack) => {
    form.setFieldValue(key, value);

    callBack((prevState) => ({ ...prevState, [key]: value.url }));
    form.validateFields([key]);
  };

  const handleSubmit = async (values) => {
    try {
      const newParams = { ...values, logo: sponsorLogo.logo };

      const response = await postSponsor(newParams);

      if (response.status === 201) {
        notification.success({ message: "Update sponsor successful" });
        navigate("/sponsors");
      }
    } catch (error) {
      notification.error({ message: "Update sponsor failed" });
      console.log("Error", error);
    }
  };

  return (
    <PageContainer title="Create new sponsor" className="sponsor">
      <ProForm
        form={form}
        name="createClubForm"
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <ProForm.Group>
          <div className="form-fields-wrapper">
            <ProFormText
              name="title"
              width={"lg"}
              label="Sponsor Name"
              placeholder="Enter sponsor name"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Sponsor name is required! My friends
                    </p>
                  ),
                },
              ]}
            />
          </div>
        </ProForm.Group>

        <ProForm.Group>
          <ProForm.Item
            label="Logo"
            name="logo"
            rules={[
              {
                required: true,
                message: () => (
                  <p className="error-message">Logo is required! My friends</p>
                ),
              },
            ]}
          >
            <ImageUpload
              callBackGetUrl={(value) =>
                handleSetImage(value, "logo", setSponsorLogo)
              }
              type={PAGES.SPONSOR}
            />
            <span style={{ fontSize: 12, fontStyle: "italic", color: "#888" }}>
              (*) width and height should not over 200x150(px) to reduce the PDF
              file size size.
            </span>
          </ProForm.Item>
        </ProForm.Group>
      </ProForm>
    </PageContainer>
  );
};

export default NewSponsor;
