import {
  ProForm,
  ProFormText,
  PageContainer,
} from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import ImageUpload from "../../../components/UploadImage";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getClubData, patchClub } from "../../../api";

const EditClub = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [logo, setLogo] = useState();
  const [clubData, setClubData] = useState();

  const fetchClubData = useCallback(async () => {
    try {
      const response = await getClubData(id);

      setClubData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  const handleSubmit = async (values) => {
    try {
      const newParams = { ...values, logo: logo?.url ?? clubData?.logo };

      const response = await patchClub(id, newParams);

      if (response.status === 200) {
        navigate("/clubs");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchClubData();
  }, [fetchClubData]);

  return (
    <PageContainer title="Edit club">
      {clubData && (
        <ProForm
          // initialValues={clubData}
          initialValues={{
            nameEn: clubData?.nameEn,
            nameAr: clubData?.nameAr,
            logo: clubData?.logo,
          }}
          name="createClubForm"
          layout="horizontal"
          onFinish={handleSubmit}
        >
          <ProForm.Group>
            <div className="form-fields-wrapper">
              <ProFormText
                name="nameEn"
                label="English Name"
                width={"lg"}
                placeholder="Enter club name in English"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        English name is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormText
                name="nameAr"
                width={"lg"}
                label="Arabic Name"
                placeholder="Enter club name in Arabic"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Arabic name is required! My friends
                      </p>
                    ),
                  },
                ]}
              />
            </div>
          </ProForm.Group>

          <ProForm.Group>
            <ImageUpload
              initialValue={clubData?.logo}
              callBackGetUrl={setLogo}
            />
          </ProForm.Group>
        </ProForm>
      )}
    </PageContainer>
  );
};

export default EditClub;
