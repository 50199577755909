import { buildQueryString } from "./buildQueryString";

export const createQueryStringUrl = (filters) => {
  const queryStringUrl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    `?${buildQueryString({ ...filters })}`;

  return window.history.replaceState(
    { path: queryStringUrl },
    "",
    queryStringUrl
  );
};
