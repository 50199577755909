import {
  ProForm,
  ProFormText,
  PageContainer,
  ProFormDateTimePicker,
  ProFormSelect,
  ProFormMoney,
} from "@ant-design/pro-components";
import { useNavigate, useParams } from "react-router";
import React, { useCallback, useEffect, useState } from "react";
import { editCoupon, getDetailCoupon } from "../../../api";
import { useForm } from "antd/lib/form/Form";
import * as dayjs from "dayjs";
import { notification } from "antd";

const CouponType = [
  { value: "PERCENTAGE", label: "PERCENTAGE" },
  { value: "FIXED_AMOUNT", label: "FIXED AMOUNT" },
];

const CouponStatus = [
  { value: "ACTIVE", label: "ACTIVE" },
  { value: "DRAFT", label: "DRAFT" },
];

const CouponEdit = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const { idCoupon } = useParams();

  const [detailCoupon, setDetailCoupon] = useState();

  const fetchCoupons = useCallback(async () => {
    try {
      const { data } = await getDetailCoupon(idCoupon);

      setDetailCoupon(data);
    } catch (error) {
      console.log("Error", error);
    }
  }, [idCoupon]);

  const handleSubmit = async (values) => {
    try {
      const newParams = {
        ...values,
        startDate: values.startDate
          ? dayjs
              .tz(values.startDate, "YYYY-MM-DD HH:mm", "Asia/Riyadh")
              .utc()
              .format()
          : null,
        endDate: values.endDate
          ? dayjs
              .tz(values.endDate, "YYYY-MM-DD HH:mm", "Asia/Riyadh")
              .utc()
              .format()
          : null,
      };

      const response = await editCoupon(idCoupon, newParams);

      if (response.status === 200) {
        navigate(`/coupon`);
        notification.success({
          message: "Edit coupon successful! My friends",
        });
      }
    } catch (error) {
      notification.error({
        message: "Edit coupon failed! My friends",
      });
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, [fetchCoupons]);

  return (
    <PageContainer title="Edit coupon">
      {detailCoupon && (
        <ProForm
          form={form}
          initialValues={{
            ...detailCoupon,
            startDate: dayjs
              .utc(detailCoupon?.startDate)
              .utcOffset(3)
              .format("YYYY/MM/DD HH:mm"),
            endDate: dayjs
              .utc(detailCoupon?.endDate)
              .utcOffset(3)
              .format("YYYY/MM/DD HH:mm"),
          }}
          name="editCouponForm"
          layout="horizontal"
          onFinish={handleSubmit}
          className="create-event-form event-form"
          onFinishFailed={({ values }) => {
            console.log("🚀 ~ values:", values);
          }}
          scrollToFirstError
        >
          <ProForm.Group>
            <div className="form-fields-wrapper">
              <ProFormSelect
                label="Choose Discount Type"
                name="discountType"
                width={"lg"}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Discount Type is required! My friends
                      </p>
                    ),
                  },
                ]}
                placeholder="Select Discount Type"
                options={CouponType}
                showSearch
              />

              <ProFormSelect
                label="Coupon Status"
                name="couponStatus"
                width={"lg"}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Coupon Status is required! My friends
                      </p>
                    ),
                  },
                ]}
                placeholder="Select Coupon Status"
                options={CouponStatus}
                showSearch
              />

              <ProFormText
                name="couponName"
                width={"lg"}
                label="Coupon Name"
                placeholder="Enter Coupon Name"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Coupon Name is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormMoney
                name="limitUser"
                width={"lg"}
                label="Limit User"
                placeholder="Enter Limit User"
                fieldProps={{
                  moneySymbol: false,
                }}
                min={1}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Limit User is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormMoney
                name="discountAmount"
                width={"lg"}
                label="Discount Amount"
                placeholder="Enter Discount Amount"
                fieldProps={{
                  moneySymbol: false,
                }}
                min={1}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Discount Amount is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormDateTimePicker
                name="startDate"
                tooltip="This always set to Saudi Arabia Timezone"
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                width={"lg"}
                label="Start on"
                placeholder="Choose start on"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Start on is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormDateTimePicker
                name="endDate"
                tooltip="This always set to Saudi Arabia Timezone"
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                width={"lg"}
                label="End On"
                placeholder="Choose End On"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        End On is required! My friends
                      </p>
                    ),
                  },
                ]}
              />
            </div>
          </ProForm.Group>
        </ProForm>
      )}
    </PageContainer>
  );
};

export default CouponEdit;
