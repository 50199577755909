import {
  PageContainer,
  ProForm,
  ProFormDateTimePicker,
  ProFormSelect,
} from "@ant-design/pro-components";
import { Button, Flex, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getEventOrderData,
  getTransactionStatus,
  patchEventOrder,
} from "../../../../api";
import {
  ORDER_PAYMENT_OPTIONS,
  ORDER_STATUS,
} from "../../../../utils/constant";
import { CustomizeOptionsByConstant } from "../../../../utils/customizeOptions";
import { Typography } from "antd";

const { Text } = Typography;

const EditEventOrder = () => {
  const { id } = useParams();
  const [form] = useForm();
  const navigate = useNavigate();

  const [orderData, setOrderData] = useState(null);
  const [dataTransaction, setDataTransaction] = useState(null);
  const [isLoadingDataTransaction, setLoadingDataTransaction] = useState(false);

  const fetchOrderData = useCallback(async () => {
    try {
      const response = await getEventOrderData(id);

      setOrderData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  const handleSubmit = async (values) => {
    try {
      const newParams = {
        ...values,
        paymentOption: values.paymentOption || null,
        boughtAt: values.boughtAt
          ? dayjs
              .tz(values.boughtAt, "YYYY-MM-DD HH:mm", "Asia/Riyadh")
              .utc()
              .format()
          : null,
      };

      const response = await patchEventOrder(id, newParams);

      if (response.status === 200) {
        navigate(-1);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchOrderData();
  }, [fetchOrderData]);

  const checkTransactionStatus = async () => {
    try {
      if (isLoadingDataTransaction) return;
      setLoadingDataTransaction(true);
      const response = await getTransactionStatus(id);
      setDataTransaction(response);
      setLoadingDataTransaction(false);
    } catch (error) {
      setLoadingDataTransaction(false);
      notification.error({ message: "Server error!" });
    }
  };
  return (
    <PageContainer title="Edit order">
      {orderData && (
        <ProForm
          form={form}
          name="editEventForm"
          layout="horizontal"
          onFinish={handleSubmit}
          className="create-event-form event-form"
          initialValues={{
            ...orderData,
          }}
          onFinishFailed={(values) => {
            console.log("🚀 ~ values:", values);
          }}
          scrollToFirstError
          submitter={{
            // Configure the button text
            searchConfig: {
              resetText: "reset",
              submitText: "submit",
            },
            // Configure the properties of the button
            resetButtonProps: {
              style: {
                // Hide the reset button
                display: "none",
              },
            },
            submitButtonProps: {},
            render: (props, doms) => {
              return [
                <Button
                  type="default"
                  onClick={() => props.form?.resetFields()}
                >
                  Reset
                </Button>,
                <Button
                  type="primary"
                  key="submit"
                  onClick={() => props.form?.submit?.()}
                >
                  Submit
                </Button>,
                <Button
                  loading={isLoadingDataTransaction}
                  onClick={checkTransactionStatus}
                  type="primary"
                >
                  Get transaction info
                </Button>,
              ];
            },
          }}
        >
          <ProForm.Group>
            <div className="form-fields-wrapper">
              <ProFormSelect
                label="Status"
                name="status"
                width={"lg"}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Status is required! My friends
                      </p>
                    ),
                  },
                ]}
                placeholder="Select status"
                options={CustomizeOptionsByConstant(ORDER_STATUS)}
                showSearch
              />

              <ProFormSelect
                label="Payment option"
                name="paymentOption"
                width={"lg"}
                placeholder="Select option"
                rules={[
                  {
                    required: false,
                    message: () => (
                      <p className="error-message">
                        Payment option is required! My friends
                      </p>
                    ),
                  },
                ]}
                options={CustomizeOptionsByConstant(ORDER_PAYMENT_OPTIONS)}
              />

              <ProFormDateTimePicker
                name="boughtAt"
                tooltip="This always set to Saudi Arabia Timezone"
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                width={"lg"}
                label="Purchase time"
                placeholder="Choose time"
                rules={[
                  {
                    required: false,
                    message: () => (
                      <p className="error-message">
                        Purchase time is required! My friends
                      </p>
                    ),
                  },
                ]}
              />
            </div>
          </ProForm.Group>
        </ProForm>
      )}
      <Flex gap="small" vertical className="wrap-transaction-response">
        {dataTransaction?.httpCode && (
          <Text>Http code: {dataTransaction?.httpCode}</Text>
        )}
        {dataTransaction?.errors && (
          <Text>Errors: {dataTransaction?.errors}</Text>
        )}

        {dataTransaction?.status_payfort_response && (
          <Text>
            Status payfort response: {dataTransaction?.status_payfort_response}
          </Text>
        )}
        {dataTransaction?.transaction_status && (
          <Text>Transaction status: {dataTransaction?.transaction_status}</Text>
        )}

        {dataTransaction?.response_code && (
          <Text>Response code: {dataTransaction?.response_code}</Text>
        )}
        {dataTransaction?.amount && (
          <Text>Amount: {dataTransaction?.amount}</Text>
        )}
        {dataTransaction?.response_message && (
          <Text>Response message: {dataTransaction?.response_message}</Text>
        )}
        {dataTransaction?.transaction_message && (
          <Text>
            Transaction message: {dataTransaction?.transaction_message}
          </Text>
        )}
      </Flex>
    </PageContainer>
  );
};

export default EditEventOrder;
