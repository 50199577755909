import {
  ProForm,
  PageContainer,
  ProFormDigit,
  ProFormSelect,
} from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import ImageUpload from "../../../../components/UploadImage";
import { useForm } from "antd/lib/form/Form";
import { getEvents, postSlider } from "../../../../api";
import { useCallback, useEffect, useState } from "react";
import { CustomizeOptionsByName } from "../../../../utils/customizeOptions";

const NewSlider = () => {
  const navigate = useNavigate();
  const [form] = useForm();

  const [events, setEvents] = useState([]);

  const fetchEvents = useCallback(async () => {
    try {
      const response = await getEvents();
      const customizeData = CustomizeOptionsByName(response);

      setEvents(customizeData);
    } catch (err) {
      console.log("err", err);
    }
  }, []);

  const handleSubmit = async (values) => {
    try {
      const response = await postSlider(values);

      if (response.status === 201) {
        navigate("/sliders");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleSetImage = (value, key) => {
    form.setFieldValue(key, value);
    form.validateFields([key]);
  };

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  return (
    <PageContainer title="Create new slider">
      <ProForm
        form={form}
        name="createSliderForm"
        layout="horizontal"
        onFinish={handleSubmit}
        className="event-form slider-form"
      >
        <ProForm.Group>
          <div className="form-fields-wrapper">
            <ProFormDigit
              name="position"
              label="Slider position"
              placeholder="Enter slider position"
              width={"lg"}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Slider position is required! My friends
                    </p>
                  ),
                },
              ]}
              fieldProps={{ controls: false }}
            />
          </div>

          <ProFormSelect
            label="Event"
            name="eventId"
            width={"lg"}
            rules={[
              {
                required: true,
                message: () => (
                  <p className="error-message">Event is required! My friends</p>
                ),
              },
            ]}
            placeholder="Select event"
            options={events}
            showSearch
          />

          <ProForm.Item
            label="Title image:"
            name="url"
            rules={[
              {
                required: true,
                message: () => (
                  <p className="error-message">
                    Title image is required! My friends
                  </p>
                ),
              },
            ]}
          >
            <ImageUpload
              callBackGetUrl={(value) => {
                handleSetImage(value.url, "url");
              }}
            />
          </ProForm.Item>
        </ProForm.Group>
      </ProForm>
    </PageContainer>
  );
};

export default NewSlider;
