import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Upload, notification } from "antd";

import React, { useState } from "react";
import { uploadImageAPI } from "../../api";
import { PAGES } from "../../utils/constant";

// const MAXIMUM_DIMENSION = {
//   width: 100,
//   height: 25,
// };

const ImageUpload = ({ callBackGetUrl, initialValue = null, type = null }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(initialValue);
  let isError = false;

  const beforeUpload = (file) => {
    return new Promise((resolve, reject) => {
      // if (type === PAGES.SPONSOR) {
      //   isError = false;
      //   // check for dimensions
      //   var reader = new FileReader();
      //   // Read the contents of Image File.
      //   reader.readAsDataURL(file);
      //   reader.onload = (event) => {
      //     // Initiate the JavaScript Image object.
      //     var image = new Image();

      //     // Set the Base64 string return from FileReader as source.
      //     image.src = event.target.result;

      //     image.onload = function () {
      //       const height = this.height;
      //       const width = this.width;
      //       // if the aspect ratio is in our sweet spot, proceed - you can specify whatever checks for height and width you want
      //       if (
      //         width > MAXIMUM_DIMENSION.width ||
      //         height > MAXIMUM_DIMENSION.height
      //       ) {
      //         isError = true;
      //         // alert('Image does not have optimal dimensions. Please crop or replace.');
      //         notification.error({
      //           message:
      //             "Image does not have optimal dimensions. Please crop or replace!",
      //         });
      //         reject(false);
      //       } else {
      //         resolve(true);
      //       }
      //     };
      //   };

      //   return;
      // }

      // check the file type - you can specify the types you'd like here:
      const validImageTypes =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/webp";
      if (!validImageTypes) {
        notification.error({
          message: "You can only upload JPG/PNG/WEBP file!",
        });
        reject(false);
      }

      // check the file size - you can specify the file size you'd like here:
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        notification.error({ message: "Image must smaller than 2MB!" });
        reject(false);
      }
      resolve(true);
    });
  };

  const handleChange = async (info) => {
    try {
      if (isError && type === PAGES.SPONSOR) return;

      if (info.file.status === "uploading") {
        setLoading(true);
        return;
      }

      let formData = new FormData();
      const fileLocalUpload =
        info.fileList[info.fileList.length - 1].originFileObj;

      formData.append("file", fileLocalUpload);

      const response = await uploadImageAPI(formData);

      setImageUrl(response);

      if (typeof callBackGetUrl === "function") {
        callBackGetUrl(response);
      }
      return;
    } catch (error) {
      console.log("Error", error);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div className="upload-wrapper">
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? (
          <img
            src={imageUrl.url ?? imageUrl}
            alt="avatar"
            style={{
              width: "100%",
            }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
    </div>
  );
};

export default ImageUpload;
