import React, { useCallback, useEffect, useState } from "react";
import {
  PageContainer,
  ProForm,
  ProFormDigit,
  ProFormSelect,
} from "@ant-design/pro-components";
import { PAYMENT_OPTIONS } from "../../../utils/constant";
import { notification } from "antd";
import { getSettings, patchSettings } from "../../../api";

const System = () => {
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement, type) => {
    switch (type) {
      case 1:
        api.error({
          message: "Update settings",
          description: "Something went wrong!",
          placement,
          duration: 3,
        });
        break;

      default:
        api.success({
          message: "Update settings",
          description: "Update settings successfully.",
          placement,
          duration: 3,
        });
        break;
    }
  };

  const fetchSettings = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getSettings();

      response.forEach((element) => {
        if (element?.key === "ENABLE_SOCKET") {
          return setFormData((prevState) => ({
            ...prevState,
            enableSocket: element?.content?.status,
          }));
        }

        if (element?.key === "PAYMENT") {
          return setFormData((prevState) => ({
            ...prevState,
            payment_option: element?.content?.payment_option,
          }));
        }

        if (element?.key === "ODOO_AUTHENTICATE") {
          return setFormData((prevState) => ({
            ...prevState,
            dbName: element?.content?.db,
            dbUser: element?.content?.login,
            dbPassword: element?.content?.password,
          }));
        }

        return setFormData((prevState) => ({
          ...prevState,
          timeoutOrder: element?.content?.limit,
        }));
      });
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSubmit = async (values) => {
    let response = null;
    try {
      const customizeParams = [
        {
          key: "ENABLE_SOCKET",
          content: {
            status: values?.enableSocket,
          },
        },
        {
          key: "PAYMENT",
          content: {
            payment_option: values?.payment,
          },
        },
        {
          key: "TIMEOUT_ORDER",
          content: {
            limit: values?.timeoutOrder || false,
          },
        },
        {
          key: "ODOO_AUTHENTICATE",
          content: {
            db: values?.dbName,
            login: values?.dbUser,
            password: values.dbPassword,
          },
        },
      ];

      response = await patchSettings(customizeParams);

      if (response?.status === 200) {
        openNotification("bottomLeft");
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      if (!response) {
        openNotification("bottomLeft", 1);
      }
    }
  };

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  return (
    <PageContainer title="Settings">
      {contextHolder}

      {formData && !isLoading && (
        <ProForm
          name="settingsForm"
          layout="horizontal"
          onFinish={handleSubmit}
          initialValues={formData}
        >
          <ProForm.Group>
            <div className="form-fields-wrapper">
              <ProFormDigit
                name="timeoutOrder"
                width={"lg"}
                min={0}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Timeout order is required! My friends
                      </p>
                    ),
                  },
                ]}
                label="Timeout for order"
                placeholder="Enter timeout"
              />

              <ProFormSelect
                name="payment_option"
                width={"lg"}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Payment is required! My friends
                      </p>
                    ),
                  },
                ]}
                label="Payment"
                placeholder="Select payment"
                options={PAYMENT_OPTIONS}
              />
            </div>
          </ProForm.Group>
        </ProForm>
      )}
    </PageContainer>
  );
};

export default System;
