/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useCallback, useEffect, useState } from "react";
import {
  getCountTickets,
  getDetailCoupon,
  getListTicketType,
} from "../../../api";
import { useParams } from "react-router-dom";
import { Badge, Card, Divider, Table } from "antd";
import { PageContainer, ProDescriptions } from "@ant-design/pro-components";
import dayjs from "dayjs";
import ModalAssignTicketTypes from "../ModalAssignTicketType";

export default function CouponDetail() {
  const { idCoupon } = useParams();

  const [detailCoupon, setDetailCoupon] = useState();
  const [page, setPage] = useState(1);
  const limit = 10;
  const [total, setTotal] = useState();
  const [ticketTypeName, setTicketTypeName] = useState("");
  const [ticketTypes, setTicketTypes] = useState([]);
  const [checkedListIdType, setCheckedListIdType] = useState([]);
  const [countTicket, setCountTicket] = useState([]);
  const [idsTicketTypeByCoupon, setIdsTicketTypeByCoupon] = useState([]);

  const fetchTicketTypes = useCallback(async () => {
    const params = {
      page: page,
      limit: limit,
      ticketTypeName: ticketTypeName,
    };
    try {
      const response = await getListTicketType(params);

      setTicketTypes(response?.results);
      setTotal(response?.total);
    } catch (error) {
      console.log("Error", error);
    }
  }, [ticketTypeName, page, limit]);

  const fetchCoupons = useCallback(async () => {
    try {
      const { data } = await getDetailCoupon(idCoupon);

      setDetailCoupon(data);
      setCheckedListIdType(data?.events);
    } catch (error) {
      console.log("Error", error);
    }
  }, [idCoupon]);

  const fetchCountTickets = async (id) => {
    try {
      const { data } = await getCountTickets(id);
      setCountTicket(data?.data);
    } catch (error) {
      console.log("🚀 ~ fetchExcelCoupon ~ error:", error);
    }
  };

  useEffect(() => {
    checkedListIdType?.map((item) => {
      const newIds = item?.ticketTypes?.map((item) => item?.id);
      setIdsTicketTypeByCoupon([...idsTicketTypeByCoupon, ...newIds]);
    });
  }, [checkedListIdType]);

  useEffect(() => {
    fetchCountTickets(idCoupon);
  }, [idCoupon]);

  // useEffect(() => {
  //   const mySet = new Set(checkedListIdType);

  //   const newCourses = ticketTypes?.filter((item) => mySet.has(item.id)) || [];
  //   setTicketTypeAdded(newCourses);
  // }, [checkedListIdType, ticketTypes]);

  useEffect(() => {
    fetchCoupons();
  }, [fetchCoupons]);
  useEffect(() => {
    fetchTicketTypes();
  }, [fetchTicketTypes]);

  return (
    <PageContainer>
      <Card title="Coupon Information">
        <div className="mb-4">
          <ModalAssignTicketTypes
            idCoupon={idCoupon}
            ticketTypes={ticketTypes}
            setTicketTypeName={setTicketTypeName}
            setPage={setPage}
            total={total}
            setCheckedList={setIdsTicketTypeByCoupon}
            checkedList={idsTicketTypeByCoupon}
            fetchCoupons={fetchCoupons}
          />
        </div>
        {checkedListIdType?.map((item) => (
          <div>
            {item?.nameAr}:{" "}
            {item?.ticketTypes.map((item) => (
              <Badge key={item.id} count={item?.nameAr} />
            ))}
          </div>
        ))}

        <Divider />

        <ProDescriptions column={1}>
          <ProDescriptions.Item label="Coupon Name" valueType="text">
            {detailCoupon?.couponName}
          </ProDescriptions.Item>

          <ProDescriptions.Item label="Coupon Status" valueType="text">
            {detailCoupon?.couponStatus}
          </ProDescriptions.Item>

          <ProDescriptions.Item label="Discount Amount" valueType="text">
            {detailCoupon?.discountAmount}
          </ProDescriptions.Item>

          <ProDescriptions.Item label="Discount Type" valueType="text">
            {detailCoupon?.discountType}
          </ProDescriptions.Item>

          <ProDescriptions.Item label="Limit User" valueType="text">
            {detailCoupon?.limitUser}
          </ProDescriptions.Item>

          <ProDescriptions.Item label="Start date">
            {dayjs(detailCoupon?.startDate).format("YYYY-MM-DD HH:mm")}
          </ProDescriptions.Item>

          <ProDescriptions.Item label="End date">
            {dayjs(detailCoupon?.endDate).format("YYYY-MM-DD HH:mm")}
          </ProDescriptions.Item>

          <ProDescriptions.Item label="Create date">
            {dayjs(detailCoupon?.createdAt).format("YYYY-MM-DD HH:mm")}
          </ProDescriptions.Item>
        </ProDescriptions>
      </Card>

      <Divider />

      <Card title="Coupon used">
        <Table dataSource={countTicket} columns={columns} />
      </Card>
    </PageContainer>
  );
}

export const columns = [
  {
    title: "Ticket type name",
    dataIndex: "ticket_type_name",
    key: "ticket_type_name",
  },
  {
    title: "Count",
    dataIndex: "count",
    key: "count",
  },
  {
    title: "Sold",
    dataIndex: "sold",
    key: "sold",
  },
  {
    title: "Expired",
    dataIndex: "expired",
    key: "expired",
  },
  {
    title: "Wait for payment",
    dataIndex: "wait_for_payment",
    key: "wait_for_payment",
  },
];
