import React from "react";
import { PageContainer, ProCard } from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import { Button } from "antd";
import { RollbackOutlined } from "@ant-design/icons";

const useLayout = (Component) => {
  const navigate = useNavigate();

  return (props) => {
    return (
      <div
        style={{
          height: "100vh",
        }}
      >
        <PageContainer>
          <ProCard
            style={{
              height: "fit-content",
              minHeight: 800,
            }}
          >
            <Button
              type="default"
              onClick={() => navigate(-1)}
              icon={<RollbackOutlined />}
            >
              Back
            </Button>
            <Component {...props} />
          </ProCard>
        </PageContainer>
      </div>
    );
  };
};

export default useLayout;
