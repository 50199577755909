import {
  ProForm,
  ProFormText,
  PageContainer,
  ProFormSelect,
} from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import { getSubCategory, patchSubCategories } from "../../../api";
import { COLORS, EVENT_TYPES } from "../../../utils/constant";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";

const EditSubCategory = () => {
  const { id } = useParams();
  const [form] = useForm();
  const navigate = useNavigate();

  const [subCategory, setSubCategory] = useState(null);

  const fetchSubCategoryDetails = useCallback(async () => {
    try {
      const response = await getSubCategory(id);

      setSubCategory(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  const handleSubmit = async (values) => {
    try {
      const newParams = {
        category: values?.category || subCategory?.category,
        nameAr: values?.nameAr || subCategory?.nameAr,
        nameEn: values?.nameEn || subCategory?.nameEn,
        backgroundColor:
          values?.color?.backgroundColor || subCategory?.backgroundColor,
        textColor: values?.color?.textColor || subCategory?.textColor,
      };

      await patchSubCategories(id, newParams);

      navigate("/sub-categories");
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchSubCategoryDetails();
  }, [fetchSubCategoryDetails]);
  return (
    <PageContainer title="Edit sub category">
      {subCategory && (
        <ProForm
          form={form}
          name="subCategoryForm"
          layout="horizontal"
          onFinish={handleSubmit}
          initialValues={{
            ...subCategory,
            color: {
              value: `${subCategory?.textColor}/${subCategory?.backgroundColor}`,
            },
          }}
        >
          <ProForm.Group>
            <div className="form-fields-wrapper">
              <ProFormText
                name="nameEn"
                label="English Name"
                width={"lg"}
                placeholder="Enter category name in English"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        English name is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormText
                name="nameAr"
                width={"lg"}
                label="Arabic Name"
                placeholder="Enter category name in Arabic"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Arabic name is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormSelect
                name="category"
                width={"lg"}
                label="Category"
                placeholder="Select category"
                options={EVENT_TYPES}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Category name is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormSelect
                label="Color"
                name="color"
                width={"lg"}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Color is required! My friends
                      </p>
                    ),
                  },
                ]}
                placeholder="Select country"
                options={COLORS}
                fieldProps={{
                  labelInValue: true,
                  optionItemRender: (item) => {
                    console.log("item", item);
                    return (
                      <div
                        style={{
                          backgroundColor: "blue",
                          color: item.textColor,
                          padding: "0px 8px",
                          borderRadius: 4,
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                          }}
                        >
                          {item.label}
                        </p>
                      </div>
                    );
                  },
                }}
                showSearch
              />
            </div>
          </ProForm.Group>
        </ProForm>
      )}
    </PageContainer>
  );
};

export default EditSubCategory;
