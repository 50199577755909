import React from "react";
import { PageContainer, ProDescriptions } from "@ant-design/pro-components";
import { Card, Divider, Drawer } from "antd";
import dayjs from "dayjs";

const PaymentLogDraw = ({ open, cancel, paymentLogData }) => {
  return (
    <Drawer
      title="Payment log details"
      width={700}
      closable={false}
      onClose={cancel}
      open={open}
    >
      <PageContainer className="ticket-draw-container">
        <Card title="Payment Log Information">
          <ProDescriptions column={1}>
            <ProDescriptions.Item label="ID" valueType="text">
              {paymentLogData.id}
            </ProDescriptions.Item>

            <ProDescriptions.Item label="Fort ID" valueType="text">
              {paymentLogData.fort_id}
            </ProDescriptions.Item>

            <ProDescriptions.Item label="Response code" valueType="text">
              {paymentLogData.response_code}
            </ProDescriptions.Item>

            <ProDescriptions.Item label="Response message" valueType="text">
              {paymentLogData.response_message}
            </ProDescriptions.Item>

            <ProDescriptions.Item label="Transaction status" valueType="text">
              {paymentLogData.transaction_status}
            </ProDescriptions.Item>

            <ProDescriptions.Item label="Service command" valueType="text">
              {paymentLogData.service_command}
            </ProDescriptions.Item>

            <ProDescriptions.Item label="Created at">
              {dayjs(paymentLogData.created_at).format("YYYY-MM-DD HH:mm")}
            </ProDescriptions.Item>
          </ProDescriptions>
        </Card>

        <Divider />

        <Card title="Related Information">
          <ProDescriptions column={1}>
            <ProDescriptions.Item label="Customer email" valueType="text">
              {paymentLogData.payfort_response.customer_email}
            </ProDescriptions.Item>

            <ProDescriptions.Item label="Phone number" valueType="text">
              {paymentLogData.payfort_response.phone_number}
            </ProDescriptions.Item>

            <ProDescriptions.Item label="Amount" valueType="text">
              {Number(paymentLogData.payfort_response.amount) / 100}
            </ProDescriptions.Item>

            <ProDescriptions.Item label="Payment option" valueType="text">
              {paymentLogData.payfort_response.payment_option}
            </ProDescriptions.Item>
          </ProDescriptions>
        </Card>
      </PageContainer>
    </Drawer>
  );
};

export default PaymentLogDraw;
