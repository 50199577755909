import { PlusOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { ProTable, PageContainer } from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { deleteEvent, getEvents, getSubCategories } from "../../api";
import { useForm } from "antd/lib/form/Form";
import { CustomizeOptionsByName } from "../../utils/customizeOptions";
import { useLocation } from "react-router-dom";
import { parseQueryString } from "../../utils/parseQueryString";
import { createQueryStringUrl } from "../../utils/createQueryStringUrl";
import { useEventTableColumns } from "../../components/Columns/Events";

const Events = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { search } = useLocation();
  const queryString = parseQueryString(search);
  const customQueryString = {
    isFeatured: queryString?.isFeatured || null,
    subCategoryId: queryString?.subCategoryId || null,
    category: queryString?.category || null,
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(null);
  const [events, setEvents] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState();
  const [isShowModal, setIsShowModal] = useState(false);
  const [filterEvents, setFilterEvents] = useState(customQueryString);
  const [columnsStateMap, setColumnsStateMap] = useState({
    nameEn: {
      show: false,
      order: 0,
    },
    locationEn: {
      show: false,
      order: 0,
    },
    cityEn: {
      show: false,
      order: 0,
    },
  });
  const [subCategories, setSubCategories] = useState([]);

  const showModal = (record) => {
    setSelectedRecord(record);
    setIsShowModal(true);
  };

  const handleCancel = () => setIsShowModal(false);

  const fetchEvents = useCallback(async () => {
    try {
      createQueryStringUrl(filterEvents);
      form.setFieldsValue(filterEvents);

      const params = {
        ...filterEvents,
        page: currentPage,
        limit: pageSize,
      };

      const response = await getEvents(params);

      setEvents(response?.results);
      setTotalItems(response?.total);
    } catch (error) {
      console.log("Error", error);
    }
  }, [currentPage, filterEvents, form, pageSize]);

  const fetchSubCategories = useCallback(async () => {
    try {
      const response = await getSubCategories();

      setSubCategories(CustomizeOptionsByName(response));
    } catch (error) {
      console.log("Error", error);
    }
  }, []);

  const handleSubmitModal = async () => {
    try {
      const response = await deleteEvent(selectedRecord?.id);

      if (response.status === 200) {
        await fetchEvents();
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      handleCancel();
    }
  };

  const handleSubmitFilter = (params) => {
    const formValues = form.getFieldsValue();

    setFilterEvents({
      ...filterEvents,
      category: params?.category || formValues?.category || null,
      isFeatured: params?.isFeatured || formValues?.isFeatured || null,
      subCategoryId: params?.subCategoryId || formValues?.subCategoryId || null,
    });

    setCurrentPage(1);
  };

  const handleResetFilter = () => {
    setFilterEvents({
      isFeatured: null,
      subCategoryId: null,
      category: null,
    });
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  useEffect(() => {
    fetchSubCategories();
  }, [fetchSubCategories]);

  const tableColumns = useEventTableColumns(subCategories, showModal);

  return (
    <PageContainer title="All Events">
      {isShowModal && (
        <Modal
          open={isShowModal}
          onOk={() => handleSubmitModal()}
          onCancel={handleCancel}
          centered
        >
          <div className="delete-content">
            <ExclamationCircleFilled style={{ fontSize: "48px" }} />

            <p>Are you sure to delete this record?</p>
          </div>
        </Modal>
      )}

      <ProTable
        rowKey="id"
        request={({ pageSize, current }, sort, filter) => {
          setPageSize(pageSize);

          return {
            // success,
            // total,
            filter,
          };
        }}
        onSubmit={(params) => handleSubmitFilter(params)}
        onReset={handleResetFilter}
        // onRow={(record) => {
        //   return {
        //     onClick: () => {
        //       navigate(`/events/${record?.id}`);
        //     },
        //   };
        // }}
        scroll={{ x: 2600, y: 500 }}
        columns={tableColumns}
        dataSource={events}
        pagination={{
          pageSize: pageSize,
          total: totalItems,
          onChange: (page) => setCurrentPage(page),
        }}
        toolBarRender={() => [
          <Button
            key="button"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate("/events/new");
            }}
            type="primary"
          >
            New Event
          </Button>,
        ]}
        search={{
          form: form,
          labelWidth: "auto",
        }}
        columnsState={{
          value: columnsStateMap,
          onChange: setColumnsStateMap,
        }}
      ></ProTable>
    </PageContainer>
  );
};

export default Events;
