import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageContainer, ProDescriptions } from "@ant-design/pro-components";
import dayjs from "dayjs";
import { Button, Card } from "antd";
import { getSliderData } from "../../../../api";

const SliderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [sliderData, setSliderData] = useState();

  const fetchClubData = useCallback(async () => {
    try {
      const response = await getSliderData(id);

      setSliderData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  useEffect(() => {
    fetchClubData();
  }, [fetchClubData]);

  return (
    <>
      {sliderData && (
        <PageContainer
          title={`Slider details`}
          extra={[
            <Button
              key="edit-waiting-room"
              type="primary"
              onClick={() => navigate(`edit`)}
            >
              Edit slider
            </Button>,
          ]}
        >
          <Card title="Slider Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item
                label="Slider ID"
                valueType="text"
                span={1}
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {sliderData?.id}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Image url" valueType="text">
                {sliderData?.url}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Position" valueType="text">
                {sliderData?.position}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Created at">
                {dayjs(sliderData?.createdAt).format("YYYY-MM-DD HH:mm")}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>
        </PageContainer>
      )}
    </>
  );
};

export default SliderDetails;
