import { Button, Typography } from "antd";
import { SortingDate, SortingText } from "../../../utils/sortingData";
import { ProFormText } from "@ant-design/pro-components";
import { DeleteFilled, FileTextFilled, EditFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const { Text } = Typography;

export const useCouponTableColumns = (showModal = () => {}) => {
  const navigate = useNavigate();

  return [
    {
      key: "couponName",
      title: "Coupon Name",
      dataIndex: "couponName",
      search: false,
      width: 300,
      // sorter: (current, next) => SortingText(current.nameEn, next.nameEn),
    },
    {
      key: "couponCode",
      title: "Coupon Code",
      dataIndex: "couponCode",
      search: false,
      width: 300,
      // sorter: (current, next) => SortingText(current.nameEn, next.nameEn),
    },
    {
      key: "couponStatus",
      title: "Coupon Status",
      dataIndex: "couponStatus",
      search: false,
      width: 300,
      // sorter: (current, next) => SortingText(current.nameAr, next.nameAr),
    },

    // filter
    {
      key: "couponInfo",
      title: "Coupon Info",
      hideInTable: true,
      renderFormItem: (
        _,
        { type, defaultRender, formItemProps, fieldProps, ...rest },
        form
      ) => {
        if (type === "form") {
          return null;
        }
        return (
          <ProFormText
            {...fieldProps}
            width={"lg"}
            placeholder="Coupon Info"
            allowClear
          />
        );
      },
    },

    {
      key: "discountAmount",
      title: "Discount Amount",
      dataIndex: "discountAmount",
      search: false,
      // sorter: (current, next) => SortingText(current.discountAmount, next.discountAmount),
    },
    {
      key: "discountType",
      title: "Discount Type",
      dataIndex: "discountType",
      search: false,
      sorter: (current, next) =>
        SortingText(current.discountType, next.discountType),
    },
    {
      key: "limitUser",
      title: "Limit User",
      dataIndex: "limitUser",
      search: false,
      sorter: (current, next) => SortingText(current.limitUser, next.limitUser),
    },

    {
      key: "startDate",
      title: "Start Date",
      dataIndex: "startDate",
      valueType: "dateTimeRange",
      search: false,
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      sorter: (current, next) => SortingDate(current.startDate, next.startDate),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.startDate)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },

    {
      key: "endDate",
      title: "End Date",
      dataIndex: "endDate",
      valueType: "dateTimeRange",
      search: false,
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      sorter: (current, next) => SortingDate(current.endDate, next.endDate),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.endDate)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },

    {
      key: "createdAt",
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "dateTimeRange",
      search: false,
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      sorter: (current, next) => SortingDate(current.createdAt, next.createdAt),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      key: "updatedAt",
      title: "Updated At",
      dataIndex: "updatedAt",
      hideInTable: true,
      search: false,
      sorter: (current, next) => SortingDate(current.updatedAt, next.updatedAt),
      render: (_, record) => {
        return <Text>{dayjs(record.updatedAt).fromNow()}</Text>;
      },
    },
    {
      key: "actions",
      title: "Actions",
      search: false,
      width: 100,
      render: (_, record) => [
        <div key={record.id} className="actions-wrapper">
          <Button
            className="icon-button"
            icon={<FileTextFilled />}
            onClick={() => navigate(`${record?.id}`)}
          />

          <Button
            className="icon-button"
            icon={<EditFilled />}
            onClick={() => navigate(`edit/${record?.id}`)}
          />

          <Button
            className="icon-button"
            icon={<DeleteFilled />}
            onClick={() => showModal(record)}
          />
        </div>,
      ],
    },
  ];
};
