import { Avatar, Button, Typography } from "antd";
import { DeleteFilled, FileTextFilled, EditFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

export const useSponsorTableColumns = (showModal) => {
  const navigate = useNavigate();

  return [
    {
      key: "logo",
      title: "Logo",
      dataIndex: "title",
      search: false,
      render: (_, record) => {
        return <Avatar src={record?.logo} />;
      },
    },
    {
      key: "title",
      title: "Name",
      dataIndex: "title",
    },
    {
      key: "createdAt",
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "dateTimeRange",
      search: false,
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      key: "updatedAt",
      title: "Updated At",
      dataIndex: "updatedAt",
      hideInTable: true,
      search: false,
      render: (_, record) => {
        return <Text>{dayjs(record.updatedAt).fromNow()}</Text>;
      },
    },
    {
      key: "actions",
      title: "Actions",
      search: false,
      width: 100,
      render: (_, record) => [
        <div key={record.id} className="actions-wrapper">
          <Button
            className="icon-button"
            icon={<FileTextFilled />}
            onClick={() => navigate(`/sponsors/${record?.id}`)}
          />

          <Button
            className="icon-button"
            icon={<EditFilled />}
            onClick={() => navigate(`/sponsors/${record?.id}/edit`)}
          />
          {showModal && (
            <Button
              className="icon-button"
              icon={<DeleteFilled />}
              onClick={() => showModal(record)}
            />
          )}
        </div>,
      ],
    },
  ];
};
