import {
  ProForm,
  ProFormText,
  PageContainer,
  ProFormDateTimePicker,
  ProFormSwitch,
  ProFormSelect,
} from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import ImageUpload from "../../../components/UploadImage";
import { useCallback, useEffect, useState } from "react";
import { getClubs, getPlaces, getSubCategories, postEvent } from "../../../api";
import {
  CustomizeCountries,
  CustomizeOptionsByApi,
  CustomizeOptionsByConstant,
} from "../../../utils/customizeOptions";
import { useForm } from "antd/lib/form/Form";
import {
  CONCERT,
  EVENT_STATUSES,
  EVENT_TYPES,
  SPORT,
} from "../../../utils/constant";
import SportType from "../SportType";
import ConcertType from "../ConcertType";
import * as dayjs from "dayjs";
import { notification } from "antd";

const NewEvent = () => {
  const [form] = useForm();
  const navigate = useNavigate();

  const [images, setImages] = useState({
    titleImage: null,
    ticketTypeImage: null,
    eventDetailImage: null,
  });
  const [clubs, setClubs] = useState([]);
  const [sportSubCategories, setSportSubCategories] = useState([]);
  const [concertSubCategories, setConcertSubCategories] = useState([]);
  const [eventType, setEventType] = useState();
  const [countries, setCountries] = useState([]);

  const fetchSubCategories = useCallback(async () => {
    try {
      const response = await getSubCategories({ select: true });

      response.forEach((item) =>
        item.category === SPORT
          ? setSportSubCategories(CustomizeOptionsByApi(item.subCategories))
          : setConcertSubCategories(CustomizeOptionsByApi(item.subCategories))
      );
    } catch (error) {
      console.log("Error", error);
    }
  }, []);

  const fetchPlaces = useCallback(async () => {
    try {
      const response = await getPlaces();

      setCountries(CustomizeCountries(response));
    } catch (error) {
      console.log("Error", error);
    }
  }, []);

  const fetchClubs = useCallback(async () => {
    try {
      const response = await getClubs();
      const customizeData = CustomizeOptionsByApi(response);

      setClubs(customizeData);
    } catch (error) {
      console.log("Error", error);
    }
  }, []);

  const handleSubmit = async (values) => {
    try {
      const newParams = {
        ...values,
        image: images.titleImage,
        ticketTypeImage: images.ticketTypeImage,
        eventDetailImage: images.eventDetailImage,
        startTime: values.startTime
          ? dayjs
              .tz(values.startTime, "YYYY-MM-DD HH:mm", "Asia/Riyadh")
              .utc()
              .format()
          : null,
        openForTicket: values.openForTicket
          ? dayjs
              .tz(values.openForTicket, "YYYY-MM-DD HH:mm", "Asia/Riyadh")
              .utc()
              .format()
          : null,
      };

      const response = await postEvent(newParams);

      if (response.status === 201) {
        navigate("/events");
        notification.success({
          message: "Create event successful! My friends",
        });
      }
    } catch (error) {
      notification.error({
        message: "Create event failed! My friends",
      });
      console.log("Error", error);
    }
  };

  const handleSetImage = (value, key, callBack) => {
    form.setFieldValue(key, value);

    callBack((prevState) => ({ ...prevState, [key]: value.url }));
    form.validateFields([key]);
  };

  useEffect(() => {
    fetchSubCategories();
    fetchClubs();
    fetchPlaces();
  }, [fetchClubs, fetchPlaces, fetchSubCategories]);

  return (
    <PageContainer title="Create new event">
      <ProForm
        form={form}
        initialValues={{
          ...form.getFieldsValue(),
          metaData: {
            guestScore: 0,
            hostScore: 0,
          },
        }}
        name="createEventForm"
        layout="horizontal"
        onFinish={handleSubmit}
        className="create-event-form event-form"
        onFinishFailed={({ values }) => {
          console.log("🚀 ~ values:", values);
        }}
      >
        <ProForm.Group>
          <div className="form-fields-wrapper">
            <ProFormText
              name="nameEn"
              label="English Name"
              width={"lg"}
              placeholder="Enter event name in English"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      English name is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormText
              name="nameAr"
              width={"lg"}
              label="Arabic Name"
              placeholder="Enter event name in Arabic"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Arabic name is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormText
              name="locationEn"
              width={"lg"}
              label="English Location"
              placeholder="Enter location"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      English location is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormText
              name="locationAr"
              width={"lg"}
              label="Arabic Location"
              placeholder="Enter location"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Arabic location is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormText
              name="cityEn"
              width={"lg"}
              label="English City"
              placeholder="Enter city"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      English city is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormText
              name="cityAr"
              width={"lg"}
              label="Arabic City"
              placeholder="Enter city"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Arabic city is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormSelect
              label="Country"
              name="country"
              width={"lg"}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Country is required! My friends
                    </p>
                  ),
                },
              ]}
              placeholder="Select country"
              options={countries}
              showSearch
            />

            <ProFormDateTimePicker
              name="startTime"
              tooltip="This always set to Saudi Arabia Timezone"
              showTime={{ format: "HH:mm" }}
              initialValue={Date.now()}
              format="YYYY-MM-DD HH:mm"
              width={"lg"}
              label="Start time"
              placeholder="Choose start time"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Start time is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormDateTimePicker
              name="openForTicket"
              tooltip="This always set to Saudi Arabia Timezone"
              showTime={{ format: "HH:mm" }}
              initialValue={Date.now()}
              format="YYYY-MM-DD HH:mm"
              width={"lg"}
              label="Open for ticket"
              placeholder="Choose time"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Open for ticket is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormSelect
              name="status"
              width={"lg"}
              label="Status"
              placeholder="Select status"
              initialValue={"WAIT_FOR_TICKET"}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Status is required! My friends
                    </p>
                  ),
                },
              ]}
              options={EVENT_STATUSES}
            />

            <ProFormSelect
              name="eventType"
              width={"lg"}
              label="Event type"
              placeholder="Select event type"
              options={CustomizeOptionsByConstant(EVENT_TYPES)}
              onChange={(value) => setEventType(value)}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Event type is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            {eventType && (
              <>
                <ProFormSelect
                  name="subCategoryId"
                  width={"lg"}
                  label="Sub category"
                  placeholder="Select sub category"
                  rules={[
                    {
                      required: true,
                      message: () => (
                        <p className="error-message">
                          Sub category is required! My friends
                        </p>
                      ),
                    },
                  ]}
                  options={
                    eventType === SPORT
                      ? sportSubCategories
                      : eventType === CONCERT
                      ? concertSubCategories
                      : []
                  }
                />
                {eventType === SPORT ? (
                  <SportType clubs={clubs} formData={form} />
                ) : (
                  <ConcertType formData={form} />
                )}
              </>
            )}
          </div>
        </ProForm.Group>

        <ProForm.Group>
          <ProForm.Item
            label="Event detail image:"
            name="eventDetailImage"
            rules={[
              {
                required: true,
                message: () => (
                  <p className="error-message">
                    Event image is required! My friends
                  </p>
                ),
              },
            ]}
          >
            <ImageUpload
              callBackGetUrl={(value) =>
                handleSetImage(value, "eventDetailImage", setImages)
              }
            />
          </ProForm.Item>

          <ProForm.Item
            label="Title image:"
            name="titleImage"
            rules={[
              {
                required: true,
                message: () => (
                  <p className="error-message">
                    Title image is required! My friends
                  </p>
                ),
              },
            ]}
          >
            <ImageUpload
              callBackGetUrl={(value) => {
                handleSetImage(value, "titleImage", setImages);
              }}
            />
          </ProForm.Item>

          <ProForm.Item
            label="Ticket type image:"
            name="ticketTypeImage"
            rules={[
              {
                required: true,
                message: () => (
                  <p className="error-message">
                    Ticket type image is required! My friends
                  </p>
                ),
              },
            ]}
          >
            <ImageUpload
              callBackGetUrl={(value) => {
                handleSetImage(value, "ticketTypeImage", setImages);
              }}
            />
          </ProForm.Item>
        </ProForm.Group>

        <ProFormSwitch
          name="isFeatured"
          label="Is featured?"
          initialValue={false}
        />
        <ProFormSwitch name="isDraft" label="Is Draft?" initialValue={false} />
      </ProForm>
    </PageContainer>
  );
};

export default NewEvent;
