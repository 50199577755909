import {
  ProForm,
  PageContainer,
  ProFormDigit,
  ProFormSelect,
} from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import { useForm } from "antd/lib/form/Form";
import { getEvents, getSliderData, patchSlider } from "../../../../api";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomizeOptionsByName } from "../../../../utils/customizeOptions";
import ImageUpload from "../../../../components/UploadImage";

const EditSlider = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { id } = useParams();

  const [sliderData, setSliderData] = useState();
  const [events, setEvents] = useState([]);

  const fetchEvents = useCallback(async () => {
    try {
      const response = await getEvents();
      const customizeData = CustomizeOptionsByName(response);

      setEvents(customizeData);
    } catch (err) {
      console.log("err", err);
    }
  }, []);

  const fetchSliderData = useCallback(async () => {
    try {
      const response = await getSliderData(id);

      setSliderData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  const handleSubmit = async (values) => {
    try {
      const response = await patchSlider(id, values);

      if (response.status === 200) {
        navigate("/sliders");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleSetImage = (value, key) => {
    form.setFieldValue(key, value);
    form.validateFields([key]);
  };

  useEffect(() => {
    fetchSliderData();
    fetchEvents();
  }, [fetchEvents, fetchSliderData]);

  return (
    <PageContainer title="Edit slider">
      {sliderData && (
        <ProForm
          form={form}
          name="editSliderForm"
          layout="horizontal"
          onFinish={handleSubmit}
          className="event-form slider-form"
          initialValues={sliderData}
        >
          <ProForm.Group>
            <div className="form-fields-wrapper">
              <ProFormDigit
                name="position"
                label="Slider position"
                placeholder="Enter slider position"
                width={"lg"}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Slider position is required! My friends
                      </p>
                    ),
                  },
                ]}
                fieldProps={{ controls: false }}
              />
            </div>

            <ProFormSelect
              label="Event"
              name="eventId"
              width={"lg"}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Event is required! My friends
                    </p>
                  ),
                },
              ]}
              placeholder="Select event"
              options={events}
              showSearch
            />

            <ProForm.Item
              label="Title image:"
              name="url"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Title image is required! My friends
                    </p>
                  ),
                },
              ]}
            >
              <ImageUpload
                initialValue={sliderData?.url}
                callBackGetUrl={(value) => {
                  handleSetImage(value.url, "url");
                }}
              />
            </ProForm.Item>
          </ProForm.Group>
        </ProForm>
      )}
    </PageContainer>
  );
};

export default EditSlider;
