import React, { useCallback, useEffect, useState } from "react";
import { Button, notification } from "antd";
import { ProTable, PageContainer } from "@ant-design/pro-components";
import { useParams, useLocation } from "react-router-dom";
import { deleteEventSponsors, postEventSponsors } from "../../../api";
import { useForm } from "antd/lib/form/Form";
import { ADD, REMOVE } from "../../../utils/constant";
import { parseQueryString } from "../../../utils/parseQueryString";
import { createQueryStringUrl } from "../../../utils/createQueryStringUrl";
import { useSponsorTableColumns } from "../../../components/Columns/Sponsors";
import { getSponsorsEvent } from "../../../api/sponsors";
import AddSponsorsModal from "../../../components/Sponsors/AddSponsorsModal";
import RemoveSponsorsModal from "../../../components/Sponsors/RemoveSponsorsModal";

const AssignSponsors = () => {
  const { id } = useParams();
  const [form] = useForm();
  const { search } = useLocation();
  const queryString = parseQueryString(search);
  const customQueryString = {
    title: queryString?.keyWord || null,
  };

  const [sponsors, setSponsors] = useState([]);
  const [filterValues, setFilterValues] = useState(customQueryString);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalItems: 0,
  });
  const [selectedSponsorIds, setSelectedSponsorIds] = useState([]);
  const [selectedSponsors, setSelectedSponsors] = useState([]);
  const [isLoading, setIsLoading] = useState({
    post: false,
    delete: false,
  });
  const [isShowModal, setIsShownModal] = useState({
    addSponsors: false,
    removeSponsors: false,
  });

  const handleToggleRemoveSponsorModal = () =>
    setIsShownModal((prevState) => ({
      ...prevState,
      removeSponsors: !prevState.removeSponsors,
    }));

  const handleToggleAddSponsorModal = () =>
    setIsShownModal((prevState) => ({
      ...prevState,
      addSponsors: !prevState.addSponsors,
    }));

  const fetchEventSponsors = useCallback(async () => {
    try {
      createQueryStringUrl(filterValues);
      form.setFieldsValue(filterValues);
      const params = {
        ...filterValues,
        page: pagination.currentPage,
        limit: pagination.pageSize,
      };

      const response = await getSponsorsEvent(id, params);

      setSponsors(response.results);
      setPagination((prevState) => ({
        ...prevState,
        totalItems: response.total,
      }));
    } catch (error) {
      console.log("Error", error);
    }
  }, [filterValues, form, id, pagination.currentPage, pagination.pageSize]);

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedSponsorIds(selectedRowKeys);
      setSelectedSponsors(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
    selectedRowKeys: selectedSponsorIds,
  };

  const handleChangePermission = async (type) => {
    try {
      switch (type) {
        case ADD:
          setIsLoading((prevState) => ({ ...prevState, post: true }));
          const postParams = {
            sponsorIds: selectedSponsorIds,
            eventId: id,
          };

          const postResponse = await postEventSponsors(postParams);

          if (postResponse.status === 201) {
            notification.success({
              message: "Assign sponsors successful",
            });
          }
          break;

        case REMOVE:
          setIsLoading((prevState) => ({ ...prevState, delete: true }));
          const deleteParams = {
            sponsorIds: selectedSponsorIds,
            eventId: id,
          };

          const deleteResponse = await deleteEventSponsors(deleteParams);

          if (deleteResponse.status === 200) {
            notification.success({
              message: "Remove sponsors successful! My friends",
            });
          }

          break;

        default:
          break;
      }
      setSelectedSponsorIds([]);
      fetchEventSponsors();
    } catch (error) {
      console.log("Error", error);
      notification.error({
        message: "Maximum for number of sponsors is 5! My friends",
      });
    } finally {
      setIsLoading((prevState) => ({
        ...prevState,
        delete: false,
        post: false,
      }));
      switch (type) {
        case ADD:
          handleToggleAddSponsorModal();
          break;

        case REMOVE:
          handleToggleRemoveSponsorModal();
          break;

        default:
          break;
      }
    }
  };

  const handleSubmitFilter = (params) => {
    const formValues = form.getFieldsValue();

    setFilterValues({
      ...filterValues,
      title: params?.title || formValues?.title || null,
    });

    setPagination((prevState) => ({ ...prevState, currentPage: 1 }));
  };

  const handleResetFilter = () => {
    setFilterValues({
      title: null,
    });
    setPagination((prevState) => ({ ...prevState, currentPage: 1 }));
  };

  const handleChangeRowColor = (record) => {
    if (record?.isHave) {
      return "bg-event-manage";
    }
  };

  useEffect(() => {
    fetchEventSponsors();
  }, [fetchEventSponsors]);

  const tableColumns = useSponsorTableColumns();

  return (
    <>
      <PageContainer title="Assign sponsors for Event">
        {/* add sponsors modal */}
        {isShowModal.addSponsors && (
          <AddSponsorsModal
            isOpen={isShowModal.addSponsors}
            handleOk={handleChangePermission}
            handleCancel={handleToggleAddSponsorModal}
          />
        )}

        {/* remove sponsors modal */}
        {isShowModal.removeSponsors && (
          <RemoveSponsorsModal
            isOpen={isShowModal.removeSponsors}
            handleOk={handleChangePermission}
            handleCancel={handleToggleRemoveSponsorModal}
          />
        )}

        <ProTable
          rowKey="id"
          request={({ pageSize, current }, sort, filter) => {
            setPagination((prevState) => ({ ...prevState, pageSize }));

            return {
              // success,
              // total,
              filter,
            };
          }}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          onSubmit={(params) => handleSubmitFilter(params)}
          onReset={handleResetFilter}
          scroll={{ x: 800, y: 500 }}
          columns={tableColumns}
          dataSource={sponsors}
          rowClassName={(record) => handleChangeRowColor(record)}
          pagination={{
            pageSize: pagination.pageSize,
            total: pagination.totalItems,
            onChange: (page) =>
              setPagination((prevState) => ({
                ...prevState,
                currentPage: page,
              })),
          }}
          toolBarRender={() => [
            <Button
              key="add-sponsors"
              onClick={handleToggleAddSponsorModal}
              type="primary"
              disabled={
                selectedSponsorIds.length < 1 ||
                selectedSponsorIds.length > 5 ||
                selectedSponsors.every((record) => record.isHave)
              }
              loading={isLoading.post}
            >
              Assign sponsors
            </Button>,
            <Button
              key="remove-sponsors"
              onClick={handleToggleRemoveSponsorModal}
              type="primary"
              danger
              disabled={
                selectedSponsorIds.length < 1 ||
                selectedSponsors.every((record) => !record.isHave)
              }
              loading={isLoading.delete}
            >
              Remove sponsors
            </Button>,
          ]}
          // search={{
          //   labelWidth: "auto",
          //   form: form,
          // }}
          search={false}
        ></ProTable>
      </PageContainer>
    </>
  );
};

export default AssignSponsors;
