import React from "react";
import { Button, Form, Modal } from "antd";
import {
  ProForm,
  ProFormDateTimePicker,
  ProFormDigit,
} from "@ant-design/pro-components";
import { useForm } from "antd/lib/form/Form";
import { ADD } from "../../utils/constant";

const AddWaitingRoomModal = ({ isOpen, handleOk = () => {}, handleCancel }) => {
  const [form] = useForm();

  const SubmitButton = ({ form }) => {
    const [submittable, setSubmittable] = React.useState(false);

    // Watch all values
    const values = Form.useWatch([], form);
    React.useEffect(() => {
      form
        .validateFields({
          validateOnly: true,
        })
        .then(
          () => {
            setSubmittable(true);
          },
          () => {
            setSubmittable(false);
          }
        );
    }, [form, values]);

    return (
      <Button type="primary" htmlType="submit" disabled={!submittable}>
        Submit
      </Button>
    );
  };

  const handleSubmit = (values) => {
    handleOk(ADD, values);
    handleCancel(ADD);
  };

  return (
    <Modal
      title="Add waiting room"
      open={isOpen}
      // onOk={handleSubmit}
      onCancel={() => handleCancel(ADD)}
      footer={null}
      centered
    >
      <ProForm
        form={form}
        name="createEventForm"
        layout="horizontal"
        labelCol={{ span: 11 }}
        onFinish={handleSubmit}
        className="create-event-form"
        onFinishFailed={({ values }) => {
          console.log("🚀 ~ values:", values);
        }}
        submitter={{
          // Configure the properties of the button
          resetButtonProps: {
            style: {
              // Hide the reset button
              // display: "none",
            },
          },
          submitButtonProps: {},

          // Fully customize the entire area
          render: (props, doms) => {
            console.log(props);
            return [
              <SubmitButton form={form} />,
              <Button onClick={() => handleCancel(ADD)}>Cancel</Button>,
            ];
          },
        }}
      >
        <ProForm.Group>
          <div className="form-fields-wrapper">
            <ProFormDateTimePicker
              name="countdownStartsOn"
              tooltip="This always set to Saudi Arabia Timezone"
              showTime={{ format: "HH:mm" }}
              initialValue={Date.now()}
              format="YYYY-MM-DD HH:mm"
              width={"lg"}
              label="Countdown start time"
              placeholder="Choose start time"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Countdown is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormDateTimePicker
              name="queueActivatesOn"
              tooltip="This always set to Saudi Arabia Timezone"
              showTime={{ format: "HH:mm" }}
              initialValue={Date.now()}
              format="YYYY-MM-DD HH:mm"
              width={"lg"}
              label="Activate queue start time"
              placeholder="Choose start time"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Activate queue is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormDigit
              name="capacity"
              width={"lg"}
              label="Limit users"
              placeholder="Enter limit users"
              min={1}
              max={4000}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Limit users is required! My friends
                    </p>
                  ),
                },
              ]}
            />
          </div>
        </ProForm.Group>
      </ProForm>
    </Modal>
  );
};

export default AddWaitingRoomModal;
