import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageContainer, ProDescriptions } from "@ant-design/pro-components";
import dayjs from "dayjs";
import { Button, Card, Image } from "antd";
import { getSponsorData } from "../../../api/sponsors";

const SponsorDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [sponsorData, setSponsorData] = useState();

  const fetchSponsorData = useCallback(async () => {
    try {
      const response = await getSponsorData(id);

      setSponsorData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  useEffect(() => {
    fetchSponsorData();
  }, [fetchSponsorData]);

  return (
    <>
      {sponsorData && (
        <PageContainer
          title={`Sponsor details`}
          extra={[
            <Button
              key="edit-waiting-room"
              type="primary"
              onClick={() => navigate(`edit`)}
            >
              Edit sponsor
            </Button>,
          ]}
        >
          <Card title="Sponsor Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item
                label="Sponsor ID"
                valueType="text"
                span={1}
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {sponsorData?.id}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Sponsor name" valueType="text">
                {sponsorData?.title}
              </ProDescriptions.Item>
            </ProDescriptions>

            <ProDescriptions column={1}>
              <ProDescriptions.Item label="Created at">
                {dayjs(sponsorData?.createdAt).format("YYYY-MM-DD HH:mm")}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Logo">
                <Image width={150} src={sponsorData?.logo} />
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>
        </PageContainer>
      )}
    </>
  );
};

export default SponsorDetails;
