import React, { useCallback, useEffect, useState } from "react";
import { PageContainer, ProDescriptions } from "@ant-design/pro-components";
import { Card, Divider, Drawer, Spin } from "antd";
import dayjs from "dayjs";
import { getOrderLogData } from "../../../api";

const OrderLogDraw = ({ open, cancel, recordId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [orderLogData, setOrderLogData] = useState(null);

  const fetchOrderLog = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await getOrderLogData(recordId);

      setOrderLogData(response);
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsLoading(false);
    }
  }, [recordId]);

  useEffect(() => {
    fetchOrderLog();
  }, [fetchOrderLog]);

  return (
    <Drawer
      title="Order log details"
      width={700}
      closable={false}
      onClose={cancel}
      open={open}
    >
      {isLoading && (
        <div className="spin-wrapper">
          <Spin />
        </div>
      )}

      {!isLoading && orderLogData && (
        <PageContainer className="ticket-draw-container">
          <Card title="Order Log Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item label="Order ID" valueType="text">
                {orderLogData.orderId}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Order number" valueType="text">
                {orderLogData.order.orderNumber}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Command" valueType="text">
                {orderLogData.command}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Created at">
                {dayjs(orderLogData.createdAt).format("YYYY-MM-DD HH:mm")}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <Card title="Related Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item label="Creator ID" valueType="text">
                {orderLogData.creatorId}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Creator" valueType="text">
                {orderLogData.creator.fullName}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Request body" valueType="text">
                <textarea
                  rows={5}
                  className="json-textarea"
                  value={JSON.stringify(orderLogData.body, undefined, 2)}
                  disabled
                />
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Old data" valueType="text">
                <textarea
                  rows={6}
                  className="json-textarea"
                  value={JSON.stringify(orderLogData.oldData, undefined, 2)}
                  disabled
                />
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>
        </PageContainer>
      )}
    </Drawer>
  );
};

export default OrderLogDraw;
