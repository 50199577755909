import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CustomEditorToolbar = ({ handleChange, contentEditor }) => {
  const modules = {
    toolbar: [
      // [{ header: "1" }, { header: "2" }, { font: [] }],
      // [{ size: [] }],
      ["bold"],
      [
        { list: "ordered" },
        { list: "bullet" },
        // { indent: "-1" },
        // { indent: "+1" },
      ],
      // ["link", "image", "video"],
      // ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const formats = [
    // "header",
    // "font",
    // "size",
    "bold",
    // "italic",
    // "underline",
    // "strike",
    // "blockquote",
    "list",
    "bullet",
    "ordered",
    // "indent",
    // "link",
    // "image",
    // "video",
  ];

  return (
    <>
      {/* <div id="toolbar" style={{ width: "100%" }}>
        <button className="ql-bold" />
        <button className="ql-list" value="bullet" />
        <button className="ql-list" value="ordered" />
      </div> */}

      <ReactQuill
        theme={"snow"}
        onChange={handleChange}
        value={contentEditor}
        modules={modules}
        formats={formats}
        bounds={".app"}
        style={{ maxWidth: "440px", height: 160 }}
      />
    </>
  );
};

export default CustomEditorToolbar;
